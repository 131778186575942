@import "src/styles/constants";

.question-template-container{
  .options-row{
    align-items: flex-end;
  }
  .switch-form-item{
    width: 36px !important;
  }
  textarea{
    resize: none;
  }
  .question-template-container__header{
    .ant-form-item{
      max-width: 180px;
    }
  }
  .ant-radio-group{
    padding: 0 !important;
  }
  .radio-group-default{
    label{
      padding: 8px 0;
    }

    .ant-radio-wrapper{
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    span.ant-radio + * {
      padding-left: 19px;
    }
    .radio-group-default__input{
        padding: 0;
    }
  }
  .ant-checkbox-wrapper  {
    &.ant-checkbox-group-item {
        padding: 8px 0;
    }
  }
  .question-template-container__item{
    background: $colorBlue10;
    border: 1px solid $colorBlue20;
    border-radius: 7px;
    padding: 32px 24px;
    .ant-form-item-required{
      &:after{
        display: none !important;
      }
    }
    &.error{
      border: 1px solid $colorRed40;
    }
  }
  .question-template-container__footer{
    align-items: center;
    button{
      background-color: transparent !important;
      &:nth-of-type(1) {
        padding-right: 12px;
      }
      &:nth-of-type(2) {
        padding-left: 12px;
      }
    }
  }
}