@import "src/styles/constants";

.separator {
  height: 1px;
  width: 100%;
  margin: 8px 0;
  background: $colorBlue20;

  &.vertical {
    height: 100%;
    width: 1px;
    margin: 0 8px;
  }
}
