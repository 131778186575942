@import "src/styles/constants";

.input-default {
  position: relative;
  input:disabled{
    -webkit-text-fill-color:$colorGray90;
    -webkit-opacity:1;
    &::placeholder{
      -webkit-text-fill-color:$colorGray50;
      -webkit-opacity:1;
    }
  }
  .ant-input-number-input, .ant-input-number-affix-wrapper {
    height: 40px;
  }

  input,
  .ant-input-affix-wrapper > input.ant-input, .ant-input-number-affix-wrapper {
    padding: 8px 12px !important;
    font-size: 16px;
    line-height: 22px;
    color: $colorGray90;
    border: 1px solid $colorGray40;
    border-radius: 4px;
    background-color: $colorWhite;

    &:hover {
      border-color: $colorGray60;
    }

    &:focus {
      border-color: $colorBlue60;
    }

    &:active {
      border-color: $colorBlue70;
    }

    &:disabled {
      color: $colorGray90;
      background-color: $colorGray10;
      border-color: $colorGray40;
    }

    &::placeholder {
      color: $colorGray50;
    }
  }

  &.ant-form-item-has-success {
    .ant-form-item-explain-success {
      display: none;
    }
  }

  &.ant-form-item-has-error {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          border-color: $colorRed40;
        }
      }
    }
  }

  .ant-form-item-explain {
    min-height: auto !important;
  }

  .ant-form-item-explain-error {
    padding-top: 4px;
    color: $colorRed40;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-input-number-status-error {
    input{
      color: $colorRed40;
    }
  }

  &.custom-input-error {
    input {
      background: $colorRed4;
    }
    background: $colorRed4;
  }
}
.ant-input-number-affix-wrapper .ant-input-number  input{
  border: none;
  box-shadow: none;
  padding: 0 !important;
  max-height: 20px;
  .ant-input-number-focused{
    box-shadow: none;
    border: none;
  }
}
.ant-input-number-affix-wrapper-focused{
  box-shadow: none;
  border: none;
}
.ant-input-number-affix-wrapper{
  width: 100% !important;
  display: flex;
  align-items: center;
  &:focus{
    box-shadow: none !important;
  }
  &.ant-input-number-affix-wrapper-status-success{
    box-shadow: none !important;
  }
  &.ant-input-number-affix-wrapper-disabled{
    color: $colorGray90;
    background-color: $colorGray10;
    border-color: $colorGray40;
    input:disabled, input{
      -webkit-text-fill-color:$colorGray90;
      -webkit-opacity:1;
    }
    .ant-input-number-disabled, .ant-input-number-input-wrap, input{
      background-color: $colorGray10;
    }
    .ant-input-number-prefix{
      color: $colorGray90 !important;
    }
    &:hover {
      border-color: $colorGray40;
    }
  }
  &:hover {
    border-color: $colorGray60;
  }
}