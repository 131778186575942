.rfp-bidding {
  display: flex;

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__left {
    max-width: 752px;
  }

  &__right {
    margin-left: 24px;
    max-width: 364px;
  }
  &__label{
    margin-bottom: 24px;
  }
  &__files {
    display: grid;
    gap: 24px;
  }

  &__files-left,
  &__files-right {
    width: 100%;
  }
}
