@import "src/styles/constants";

.vendor-details-form__zip-code-field {
  max-width: 174px;
}

.vendor-details-form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.vendor-details-form__grid-row {
  display: grid;
  grid-template-columns: repeat(2, 364px);
  grid-column-gap: 24px;
}

.vendor-details-form__grid-column {
  display: grid;
  grid-template-rows: repeat(5, minmax(44px, auto));
  grid-row-gap: 24px;
}

.warning-label-container {
  gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;
  background-color: $colorYellow10-2;
  margin-top: -20px !important;

  &__content {
    width: calc(100% - 24px - 13px);
  }

  svg {
    width: 16px;
    height: 16px;
    margin-top: 1px;

    path {
      fill:$colorYellow50;
    }
  }

  .search-param {
    font-weight: 700;
  }
}

.vendor-permissions-wrapper {
  .guest-radio-label {
    span {
      color: $colorGray70;
    }
  }

  .ant-form-item {
    align-items: flex-start;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    label {
     font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.view {
    .ant-form-item-label {
      label {
        color: $colorGray70;
      }
    }
  }

  .typography-body-1 {
    span {
      color: $colorGray70;
    }
  }
}
