@import "src/styles/constants";

.checkbox-default {
  &.ant-form-item-has-error {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
  }

  .ant-checkbox-wrapper {
    align-items: flex-start;
    &:hover {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorBlue10;
      }
    }

    &:focus {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorBlue20;
      }
    }

    &:active {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorWhite;
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $colorBlue70 !important;
      border-color: $colorBlue70 !important;
    }
  }

  .ant-checkbox {
    top: 0;
    padding: 3px;

    + span {
      padding-left: 16px;
      font-size: 16px;
      line-height: 24px;
      color: $colorGray90;
    }
    border: none !important;

    &::after {
      visibility: hidden !important;
    }
  }

  .ant-checkbox-inner {
    margin-right: 0;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    border: 2px solid $colorGray70 !important;
  }

}
