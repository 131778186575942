@import "src/styles/breakpoint";

.terms-of-use-wrapper{
  max-width: 930px;
  padding: 0;
  @media only screen and (max-width: $screen-sm) {
    max-width: calc(100vw - 38px);
    //padding-bottom: 20px;
    &.container{
      height: 100%;
    }
  }
  &__header{
    margin-bottom: 16px;
  }
}