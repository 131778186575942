@import "src/styles/constants";
.form_avatar {
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
}
.form_avatar-container{
  &.isChannel{
    .ant-avatar{
      border: 2px solid $colorBlue60;
      background: $colorBlue20;
      color: $colorBlue60;
    }
  }
}
