@import "src/styles/constants";

.icon-name {
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
}

.name-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__row {
    gap: 10px;
    &_highlighted {
      color: $colorBlue70;
    }
  }
  .typography-caption {
    color: $colorGray60;
  }
}


.status-icon {
  &-wrapper {
    position: relative;
  }
  position: absolute;
  top: 20px;
  right: -4px;
  &-circle {
    width: 10px;
    height: 10px;
    border: 1px solid $colorWhite;
    border-radius: 50%;
    background-color: $colorGray50;
    &.active {
      background-color: $colorGreen30;
    }
  }
}