@import "src/styles/constants";

.field-with-label {
  display: flex;
  flex-direction: column;

  .typography-label {
    margin-bottom: 8px;
    color: $colorGray70;
  }
}
