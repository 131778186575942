@import "src/styles/constants";

.file-upload {
  &__button {
    &--is-disabled {
      pointer-events: none;
    }
  }
  &__button--not-allowed {
    cursor: not-allowed;
  }
}
