
.clinical-review-container{
  height: 100%;
  &__empty-state{
    &__wrapper{
      max-width: 461px;
      text-align: center;
    }
    margin-top: 20%;
    display: flex;
    justify-content: center;
  }
}