@import "src/styles/constants";
@import "src/styles/breakpoint";

.ant-notification {
  margin-right: 32px !important;
  @media only screen and (max-width:$screen-sm-max) {
    margin: 0 16px !important;
  }
}

.ant-notification-notice {
  display: flex;
  justify-content: space-between;
  max-height: 500px !important;
  min-width:280px;
  max-width: 530px !important;
  width: auto !important;
  overflow: auto;
  padding: 12px 16px !important;
  border-radius: 2px;
  box-shadow: 0 4px 12px rgba(24, 67, 98, 0.12) !important;
  background-color: rgba(256, 256, 256, 0.8) !important;
  backdrop-filter: blur(3px);

  .ant-notification-notice-icon {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .ant-notification-notice-message {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $colorGray90;
    padding-right: 32px !important;
    margin: 0 0 0 34px !important;
    white-space: pre-wrap;
  }

  .ant-notification-notice-close {
    position: static;
    align-self: center;
  }

  .ant-notification-notice-close-x {
    height: 14px;
    max-height: 14px;
  }
}
