@import "src/styles/constants";

.segmented-component.ant-segmented {
  padding: 0;
  background: $colorGray10;
  border: 1px solid $colorGray30;
  border-radius: 4px;

  .ant-segmented-item {
    width: auto;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid $colorGray30;

    .ant-segmented-item-label {
      padding: 2px 22px;
      font-size: 14px;
      font-weight: 400;
      color: $colorGray70;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
      border: 0;
    }
  }

  .ant-segmented-item-selected {
    background: $colorBlue10;
    box-shadow: none;

    .ant-segmented-item-label {
      font-weight: 600;
      color: $colorBlue80;
    }
  }

  .ant-segmented-thumb {
    background: $colorBlue10;
  }
}
