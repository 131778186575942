@import "src/styles/constants";

.nav-link-default {
  display: flex;
  padding: 8px 16px 8px 32px;
  gap: 12px;
  width: 100%;
  align-items: center;
  min-width: 236px;

  &__link-content {
    column-gap: 12px;
  }

  span {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $colorWhite;
  }

  &:hover {
    background: $colorBlue60;
  }

  &_active {
    background: $colorBlue80;
  }

  &:focus {
    background: $colorBlue80;
    border: 1px solid $colorBlue20;
    padding: 7px 15px 7px 31px;
  }
  
  &:focus:not(:focus-visible) {
    background: $colorBlue60;
    border: 1px solid $colorBlue20;
    padding: 7px 15px 7px 31px;
  }
}
