@import 'src/styles/constants';
@import 'src/styles/breakpoint';

.popup-default {
  .ant-modal-body {
    padding: 0 32px 0 0;
    margin-right: -32px;
    overflow-y: scroll;
    max-height: calc(100vh - 254px);
  }

  .ant-modal-content {
    border-radius: 8px;
    padding: 32px;
    box-shadow: none;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0 0;
    border: none;

    & > div {
      display: flex;
    }

    button {
      &:not(:first-of-type) {
        margin-left: 16px !important;
      }
    }
  }

  .ant-modal-header {
    padding: 0 0 8px 0;
    border: none;
  }

  .ant-modal-title {
    color: $colorGray90;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .ant-modal-close-x {
    margin-top: 15px;
    margin-right: 15px;
  }

  &--closable {
    .ant-modal-content {
      padding-top: 48px;
    }

    .ant-modal-body {
      padding: 16px 32px 16px 0;
    }
  }

  &--with-footer {
    .ant-modal-body {
      padding-bottom: 16px;
    }
  }

  &--has-archive-btn {
    .ant-modal-footer {
      justify-content: space-between;
    }
  }
}

.popup-default-wrap {
  @media only screen and (min-width: $screen-sm + 1) {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.popup-over {
    z-index: 1001;
  }
}

.table-popup{
  height: 494px;
}
.terms-wrap-modal{
  overflow-y: hidden !important;
}

.terms-popup{
  width: 100vw !important;
  max-height: 100vh !important;
  background: $colorWhite !important;
  max-width: 100vw !important;
  margin: 0 auto !important;
  @media only screen and (max-width: $screen-md) {
    max-height: 100dvh !important;
  }
  .terms-logo{
    &:hover{
      cursor: pointer;
    }
  }
  .ant-modal-content{
    overflow: hidden;
    height: 100vh;
    max-height: 100vh;
    padding: 24px 32px;
    @media only screen and (max-width: $screen-md) {
      height: 100dvh;
      max-height: 100dvh;
    }
    .ant-modal-body{
      max-height: calc(100vh - 92px);
      display: flex;
      justify-content: center;
      padding: 32px;
      margin-left: -32px;
      @media only screen and (max-width: $screen-md) {
        padding: 0 16px 36px;
        max-height: calc(100dvh - 114px);
      }
      @media only screen and (max-width: $screen-sm) {
        padding: 0 16px 36px;
        max-height: calc(100dvh - 102px);
      }

    }
    .ant-modal-header{
      padding-bottom: 24px;
    }
  }
}

.modal-mask-auth-pages{
  @media only screen and (max-width: $screen-sm) {
    background-color: rgba(0, 0, 0, 0.4);
  }

}