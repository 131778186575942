@import "styles/constants";
.community-users {
  &.available-for-evaluation{
    .ant-avatar{
      height: 32px;
      width: 32px;
    }
    .ant-avatar-string{
      font-size: 12px;
      line-height: 16px;
    }
    .page-number{
      margin-left: 12px;
      color: $colorGray60;
    }
    .ant-table-cell{
      vertical-align: middle;
      .separate-item{
        min-height: 36px;
        height: 36px;
        display: block;
       }
    }
    .cell-with-subtitle__title{
      .typography{
        max-width: 210px;
      }
    }
  }
}

.evaluate-container{
  button{
    padding:0 !important;
    height: 24px;
  }
  .ant-btn-ghost{
    background-color: transparent !important;
  }
}