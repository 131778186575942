@import "src/styles/constants";
@import "src/styles/breakpoint";

.user-form {
  display: grid;
  max-width: 752px;
  grid-gap: 24px;
  grid-template-columns: 364px 364px;
  position: relative;

  @media only screen and (max-width: $screen-lg-min) {
    grid-template-columns: calc(100vw / 2 - 32px - 12px) calc(100vw / 2 - 32px - 12px);
  }
  @media only screen and (max-width: $screen-sm-max) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_cs{
    width: 364px;
  }

  &_input-wide {
    .ant-form-item-control-input {
      max-height: 40px;
    }
    grid-column: span 2;
    label {
      font-weight: 600;
      font-size: 12px !important;
      line-height: 16px;
    }
  }

  &__switch {
    .ant-row.ant-form-item {
      width: auto !important;
    }
  }

  &_community {
    display: grid;
  }
 &_contact-person{
   position: absolute;
   bottom: 86px;
   left: calc(100% + 24px);
   width: 370px;
   &.commons{
     top: 0;
     bottom: initial;
   }
 }
  &_goal {
    position: absolute;
    bottom: 0;
    left: calc(100% + 24px);
    width: 170px;

    &_label {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 9px;
    }
  }

  &_hospitals {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &_hospitals_header {
    display: flex;
    justify-content: space-between;
    grid-column: span 2;

    &.disabled {
      svg {
        path {
          fill: $colorGray40 !important;
        }
      }
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: $colorRed40;
      }
    }
  }

  &_hospitals_selects {
    display: grid;
    grid-template-columns: 364px 364px;
    grid-gap: 24px;
    width: 100%;
    @media only screen and (max-width: $screen-lg-min) {
        grid-template-columns: repeat(2, calc(100vw / 2 - 32px - 12px));
      }

    @media only screen and (max-width: $screen-sm) {
      display: flex;
      flex-direction: column;

    }
  }

  &_hospitals-last {
    margin-top: 16px;
  }
  &_delete-btn {
    &.custom-button {
      svg path {
        fill: $colorRed40;
      }
    }
  }

  &_avatar {
    position: relative;
    margin-bottom: 24px;
    width: fit-content;

    .ant-upload.ant-upload-select-picture-card {
      border-radius: 100% !important;
      margin-top: 10px;
      margin-left: 8px;

      .ant-avatar-group .ant-avatar {
        border-width: 2px;
        position: relative;
      }
    }

    &-icon {
      &.ant-avatar {
        position: relative;
        width: 120px;
        height: 120px;

        .ant-avatar-string {
          font-weight: 400;
          font-size: 48px;
          line-height: 54px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    &:hover {
      .user-form_avatar_hover-cover {
        height: 116px;
        width: 116px;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: center;
        gap: 4px;
        position: absolute;
        text-transform: uppercase;
        z-index: 1000000;
        color: $colorWhite;
        background-color: rgba(0, 0, 0, 0.589);
      }
    }

    &_hover-cover {
      display: none;
    }
  }

  &_container {
    max-width: 752px;
    @media only screen and (max-width: $screen-md) {
      height: 100%;
    }
  }

  &_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &_add-hospital {
    display: flex;
    align-items: baseline;
    width: fit-content;
    grid-column: span 2;
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 44px;
    //margin-top: 32px;
  }
}
