@import "styles/constants";

.clinical-review-form-container{
  max-width: 558px;
  &__item{
    padding-bottom: 20px !important;
    border-bottom: 1px solid $colorGray30;
    .ant-space-item{
      padding: 8px 0;
    }
    &.error{
      border-bottom-color: $colorRed40;
    }
    .ant-form-item-label{
      text-align: left;
    }
    .error-message{
      color: $colorRed40;
    }
    label{
      font-weight: 700;
      font-size: 16px !important;
      line-height: 24px;

    }
    &__assigned{
      &__row{
        margin-top: 0 !important;
        width: auto !important;
        &__role{
          color: $colorGray70;
        }
        &.vendors{
          width: 67% !important;
        }
      }

    }
    &__value{

    }
  }
}