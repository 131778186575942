@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat__grid {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-rows: 80px 1fr auto;
  @media only screen and (max-width: $screen-md) {
    height: calc(100dvh - 68px);
  }
  @media only screen and (max-width: $screen-sm) {
    height: calc(100dvh - 56px);
    overflow-x: hidden;
  }
  &.search{
    grid-template-rows: 80px 56px 1fr auto;
  }
}
.chat__header {
  padding-right: 18px;
  border-bottom: 1px solid $colorGray30;
  @media only screen and (max-width: $screen-sm) {
    padding-right: 16px;
  }
}
.chat__history {
  overflow: hidden;
}

.chat-input-wrapper {
  border-top: 1px solid $colorGray30;
}
.chat-empty {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.chat-empty__text {
  color: $colorGray80;
  text-align: center;
}

.chat-header-avatar{
  width: 32px;
  height: 32px;
}
.chat-list-feature-item__caption{
  user-select: none;
}

.chat-details-sidebar{
  .ant-drawer-content-wrapper{
    @media only screen and (max-width: $screen-md) {
      width: calc(100vw - 272px) !important;
    }
    @media only screen and (max-width: $screen-sm) {
      width: 100vw !important;
      border-radius: 0;
      .ant-drawer-content{
        border-radius: 0;
      }
    }
  }
}