@import "src/styles/constants";

.commons-users-list {
  &__actions {
    column-gap: 8px;
  }

  &__search {
    width: 364px;
  }
}
