@import "src/styles/constants";

.initiate-clinical-review-wrapper{
  &__form{
    &__group{
      .ant-checkbox-group{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .ant-checkbox-wrapper{
          margin-right: 0;
          align-items: center;
          span{
            &:nth-of-type(2){
              padding-right: 0;
              width: 100%;
            }
          }
        }
        .stakeholder-with-categories-field{
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .ant-checkbox + span{
            padding-left: 0;
          }

          .custom-select{
            flex-shrink: 0;
            width: 260px;
          }
        }
      }
    }
  }
}


#refresh-stakeholders{
  top: 14px;
  right: 0;
}

.stakeholder-with-categories-field{

}
