@import "src/styles/constants";

.rfp-creation-form {
  max-width: 752px;
  .ant-row {
    //max-height: 62px;
  }
  .participation-type-view, .rfp-type-view{
    max-width: 267px;
    width: calc(50% - 8px);
    .typography-label{
      margin-bottom: 4px;
    }
  }
  .rfp-type-wrapper{
    .ant-row {
      max-height: fit-content;
    }
    .ant-form-item{
      .ant-form-item-label{
        line-height: 16px;
        text-align: left;
        font-size: 12px;
        label{
          height: 24px;
          font-weight: 600;
          font-size: 12px;
          color: $colorGray70;
        }
      }
      .radio-group-default{
        padding: 0 !important;
        .ant-radio-wrapper{
          align-items: baseline;
        }
        .rfp-type-option{
          padding-left: 30px;
          color: $colorGray70;
        }
        .ant-space-vertical{
          gap: 0 !important;
        }
        .ant-space-item{
          &:nth-of-type(3){
            margin-top: 12px;
          }
        }
      }
    }
    &.participants{
      .ant-form-item {
        .radio-group-default {
          .ant-space-vertical {
            gap: 12px !important;
          }
        }
      }
    }
  }
}
