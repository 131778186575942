@import "src/styles/constants";
@import "src/styles/breakpoint";

.header {
  padding: 24px 12px 0 32px;
  @media only screen and (max-width: $screen-md) {
    padding: 24px 15px 0 16px;
  }
  &__title{
    @media only screen and (max-width: $screen-sm) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .ant-btn {
    height: 32px;
    padding:  8px;
  }
  .button-menu.ant-btn .button-menu__arrow{
    display: none;
  }
}
.create-chat-button.button-icon.custom-button.ant-btn {
  width: 16px;
  height: 16px;
  padding: 0;
}
.button-menu__arrow svg {
  display: none !important;
}

.button-default.ant-btn svg {
  margin-right: 0;
}
.chat-list-tabs{
  @media only screen and (max-width: $screen-md) {
    ul{
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      li{
        min-width: 88px !important;
        border-bottom: 1px solid $colorGray50;
      }
    }
  }
  .custom-menu{
    position: relative;
    z-index: 2;
  }
}
