@import "styles/constants";
.check-ins-container__details{
  height: 100%;
  overflow-y: auto;

}
.check-ins-details-container{
  padding: 32px;
  &__vendor-info{
    &__avatar{
      .ant-avatar{
        width: 80px;
        height: 80px;
        font-size: 32px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        align-items: center;
      }
      .ant-avatar-string{
        position: relative !important;
      }
    }
    &__mail{
      color: $colorGray70;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: underline;
      &:hover{
        color: $colorGray70;
      }
    }
    &__phone{
      color: $colorGray70;
    }
  }
  &__active-check-in{
    padding: 24px;
    background-color: $colorBlue70;
    border-radius: 8px;
    color: $colorWhite!important;
    gap: 8px;
    &__separator{
      background-color: $colorBlue60;
      width: 100%;
      height: 1px;
      margin: 16px 0;
    }
  }
  &__prev-check-ins{
    padding-bottom: 32px;
    .infinite-list-container{
      overflow: hidden !important;
      flex: 1;
    }
    .ant-collapse{
      flex: 1;
    }
    .ant-collapse-header{
      width: 100%;
    }
    &__collapse-header{
      &__duration{
        margin-top: 5px;
        &__value{
          margin-right: 48px;
          color: $colorGray70;
        }
      }
    }
    &__collapse-content{
      padding: 16px 24px;
    }
  }
  .check-in-row{
    &__name{
      width: 234px;
    }
  }
}