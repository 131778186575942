@import "src/styles/constants";

.cpr-details-preview {
  display: flex;
  flex-direction: column;
}
.cpr-details-preview__container {
  display: grid;
  grid-template-columns: minmax(auto, 364px);
  grid-column-gap: 24px;
}
.cpr-details-preview__grid {
  display: grid;
  grid-template-rows: repeat(3, 44px);
  grid-row-gap: 24px;
}