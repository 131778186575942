@import "src/styles/constants";

.create-group-chat {

  &_form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    overflow-y: auto;

    .ant-input-prefix {
      //scale: 0.7;
  }
  }

  &_separator {
    height: 1px;
    width: 100%;
    background-color: $colorGray30;
  }

  &_chips {
    background: $colorBlue70 !important;
    color: $colorWhite !important;
    display: flex;
    flex-direction: row;
    height: 28px !important;
    margin-top: 4px !important;

    svg path {
      fill: $colorWhite;
    }
    svg {
      margin-bottom: -3px;
      margin-left: 5px;
    }
  }

  &_selected-users {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
    margin-top: -4px;
    // max-height: 70px;
    // min-height: 35px;
    // overflow: auto;
  }

  &_users-list_container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 68px;
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 16px;
      :not(:first-child) {
        margin-left: 0px;
      }
      .ant-checkbox {

        .ant-checkbox-inner {
          margin-right: 0;
          border-radius: 1px;
          width: 12px;
          height: 12px;
          border: 1px solid $colorGray70 !important;

        }
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner::after {
          margin-top: -3px;
          margin-left: -1px;
          scale: 0.6;

      }
      .ant-checkbox-inner {
        border-color: $colorBlue70 !important;
      }
      }
    }

    .ant-checkbox-wrapper {
      align-items: center !important;
    }

    .ant-checkbox span{
      margin-bottom: 7px !important;
    }
  }

  &_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 16px 32px 32px 16px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $colorWhite;
    width: 100%;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0px 0px 8px 8px;
    height: 88px;

  }



}
