@import "src/styles/constants";
.prs-participants-form {
  .clinic-sponsor-container{
    max-width: 752px;
  }
  .long-row-participants{
    max-width: 1140px;
    .ant-row{
      &:nth-of-type(1){
        max-width: 752px;
        width: 100%;
      }
      &:nth-of-type(2){
        max-width: 364px;
        width: 100%;
      }
    }
  }
}
