@import "src/styles/constants";

.community-users {
  .cell-with-subtitle {
    &__title {
      .typography {
        max-width: 140px;
      }
    }
  }
}
