@import "src/styles/constants";

.support-information-request-container{
  padding: 24px;

  &__attachment {
    .attachment__user{
      display: none;
    }
  }
}