#contract-creation-form{
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  label{
    &::after{
      display: none;
    }
  }
}