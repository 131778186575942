@import "src/styles/constants";

.add-amendment-form {
  &__attachment {
    width: 100%;
  }

  &__lop-label {
    color: $colorGray70;
  }
}
