.rfp-details-responsible {
  display: flex;
  flex-direction: column;
}
.responsible-popup{
  .ant-modal-body {
    overflow-y: visible !important;
  }
}

