@import "src/styles/constants";
@import "src/styles/breakpoint";
@import "src/styles/mixins";

.prs-voting-header{
  max-width: 752px;
  &__select{
    width: 267px;
  }
}

.open-my-results{
  max-width: 156px !important;
}

.prs-modal-create-survey{
  .ant-modal-footer{
    padding: 16px 32px 0;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0 0 8px 8px;
    margin-left: -32px;
    width: 680px;
  }
  @media only screen and (max-width: $screen-md) {
    @include mobileModal;
    .ant-modal-content{
      max-height: 100dvh;
      height: 100%;
      border-radius: 0;
    }
  }
}