@import "src/styles/constants";

.loader-global {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  &--smooth {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    opacity: 1;
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s reverse linear infinite;

    img {
      transform: scaleY(-1);
    }
  }

  &__message {
    margin-top: 32px;
    max-width: 261px;
    color: $colorWhite;
    text-align: center;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
