@import "src/styles/constants";

.rebate-conditions-preview {
  max-width: 378px;

  &--wide {
    max-width: 492px;
  }

  .field-with-label {
    width: 100%;

    .typography.typography-label {
      margin-bottom: 4px;
    }
  }

  &__hospital-spend {
    &.field-with-label {
      width: 50%;
    }
  }
}
