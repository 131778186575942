@import "src/styles/constants";

.breadcrumb-default {
  &.ant-breadcrumb {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;

    .ant-breadcrumb-link {
      cursor: pointer;
      color: $colorBlue70;

      a {
        color: $colorBlue70;
        cursor: pointer;
        &:hover {
          color: $colorBlue80;
        }

        &:active, &:focus {
          color: $colorBlue90;
        }
      }

      span {
        color: $colorGray60;
      }

      &:disabled {
        color: $colorGray60;
      }
    }

    .ant-breadcrumb-separator {
      margin: 0 4px;
      svg {
        margin-bottom: -4px;
      }
    }

    .breadcrumb-current {
      color: $colorGray50;
    }
  }
}
