@import "src/styles/constants";

.bank-account-wrapper{
  display: flex;
  gap: 16px;
  align-items: flex-start ;
  width: 100%;
  &__header{
    gap: 8px;
  }
  .details-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    gap: 24px 24px;
    .field-with-label{
      &:nth-of-type(1) {
        grid-column: 1 / 4;
        grid-row: 1;
      }
    }
  }
  &.health-system-account{
    button{
      background-color: transparent !important;
    }
  }
}

.link-bank-account{
  &__btn {
    padding: 0 !important;
    align-items: center !important;
    background-color: transparent !important;
  }
}