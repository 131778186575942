@import "src/styles/constants";

.custom-select__dropdown.autocomplete {
  z-index: 10005 !important;
}
.select-default {
  .ant-select-clear{
    path{
      fill: $colorGray70;
    }
  }
  &.ant-form-item {
    .ant-select-open {
      .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    &.ant-form-item-has-error {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          input {
            border-color: $colorRed40;
          }
        }
      }
    }

    &.ant-form-item-has-error .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $colorRed40 !important;
    }

    .ant-select-disabled {
      .ant-select-selection-item {
        color: $colorGray90;
      }
    }

    .ant-form-item-explain {
      min-height: auto;
    }

    .ant-select-arrow {
      right: 8px;
    }

    .ant-select-dropdown {
      padding: 0;
    }

    .ant-select-selector.ant-select-select-disabled {
      line-height: 24px;
      color: $colorGray90;
      background: $colorGray10;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: $colorGray10;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-color: $colorBlue70;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
      border-color: $colorGray60;
    }
  }

  &.custom-select-error {
    .ant-select {
      .ant-select-selector {
        background: $colorRed4;
        border-color: $colorRed40 !important;
      }
    }
  }
}

.placeholder-show {
  .ant-select-selection-item {
    &::after {
      content : 'Please select' !important;
      visibility: visible !important;
      width: unset !important;
      color: $colorGray50;
    }
  }
}