@import "src/styles/constants";
.attachments-list {
  &__files  {
    &.chat{
      margin: 0;
      //padding: 24px 6px 24px 12px;
      padding-top: 4px;
      padding-left: 24px;
      padding-bottom: 12px;
      border-bottom: 1px solid $colorGray30;
      display: flex;
      flex-direction: row;
      gap: 12px;
      max-width: calc(100vw - 396px - 289px);
      width: 100%;
      overflow-x: scroll;
        li{
          position: relative;
          .attachment{
            padding: 28px 12px 32px;
            max-width: 264px;
            background-color: $colorBlue10;
            border-radius: 4px;
          }
          .attachment__actions{
            position: absolute;
            right: 4px;
            top: 4px;
            svg{
              path{
                fill: $colorGray70 !important;
              }
            }

          }
        }
    }
  }
}
