.table-date-cell {
  &.ant-picker {
    padding: 0;
    border: none !important;
  }

  &--with-error {
    &.ant-picker {
      background-color: transparent;
    }
  }
}
