@import "src/styles/constants";

.input-password {
  position: relative;

  &.ant-form-item-has-success {
    .ant-form-item-explain-success {
      display: none;
    }
  }

  &.ant-form-item-has-error {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          border-color: $colorRed40;
        }
      }
    }
  }

  input,
  .ant-input-affix-wrapper > input.ant-input {
    padding: 8px 36px 8px 12px;
    font-size: 16px;
    line-height: 22px;
    color: $colorGray90;
    border: 1px solid $colorGray40;
    border-radius: 4px;

    &-focused {
      border-color: $colorBlue60;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      box-shadow: none;

      &:hover {
        border-color: $colorGray60;
      }

      &:focus {
        border-color: $colorBlue60;
      }

      &:active {
        border-color: $colorBlue70;
      }

      &::placeholder {
        color: $colorGray50;
      }
    }

    &:disabled {
      color: $colorGray70;
      background-color: $colorGray10;
      border-color: $colorGray40;
    }
  }

  .ant-form-item-explain {
    min-height: auto !important;
  }

  .ant-form-item-explain-error {
    padding-top: 2px;
    color: $colorRed40;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .ant-input-password-icon {
    position: absolute;
    right: 12px;
    font-size: 16px;
    top: 32px;
  }
}
