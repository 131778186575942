@import "src/styles/constants";

.ant-popover {
  &.button-menu__popover {
    padding: 0 !important;

    .ant-popover-inner {
      box-shadow: $boxShadow_1 !important;
      border-radius: 0 0 4px 4px !important;

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}

.button-menu {
  &.ant-btn {
    .button-menu__arrow {
      display: flex;
      align-items: center;

      svg {
        margin: 0 0 0 8px;
      }
    }
  }

  &.upperCase {
    text-transform: uppercase;
  }

  &__options {
    min-width: 180px;
  }

  &__option {
    padding: 8px 12px;
    cursor: pointer;

    svg {
      width: 24px;

      path {
        fill: $colorGray70;
      }
    }
  }
}
