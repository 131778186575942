@import "styles/constants";
@import "styles/breakpoint";

.table-default {
  .action-wrapper-separator {
    margin: 0 8px;
    width: 1px;
    background: $colorGray40;
    height: 23px;
    display: inline-block;
  }

  .action-wrapper {
    margin-top: 6px;
    height: 23px;
    display: inline-block;
  }

  .ant-table-measure-row {
    display: none;
  }

  .ant-pagination-item {
    width: 30px;
  }

  .ant-dropdown {
    height: 100%;
    z-index: 1000;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    padding: 24px 12px;

    &__title {
      color: $colorGray80;
    }

    &__subtitle{
      margin-top: 4px;
      white-space: pre-line;
      color: $colorGray80;
    }

    .button-default {
      align-self: center;
      margin-top: 12px;
    }

    .button-menu__wrapper {
      width: fit-content;
      margin: 12px auto 0;
    }
  }

  .ant-table-column-sort {
    background-color: inherit;
  }

  .ant-table-column-title {
    align-items: center;
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 4px 0 4px rgba(24, 67, 98, 0.12);
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -4px 0 4px rgba(24, 67, 98, 0.12);
  }

  .ant-table-selection {
    top: 2px;
  }

  .ant-table-thead {
    th {
      padding: 8px 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $colorGray90;
      background-color: $colorGray20;
      box-shadow: inset 0px -1px 0px $colorBlue30;
      border: none;
      vertical-align: baseline;

      &::before {
        display: none;
      }

      .ant-dropdown-trigger {
        max-height: 24px;
        display: inline-block;
        margin: 0 0 0 8px;
        padding: 4px;
        border-radius: 2px;

        &.ant-dropdown-open,
        &:hover {
          background-color: $colorGray30;
        }

        &:focus {
          border: 1px solid $colorBlue70;
          border-radius: 2px;
          background-color: $colorGray30;
        }
      }
    }
  }

  .ant-table-tbody {
    td {
      padding: 8px 12px;
      font-size: 16px;
      line-height: 24px;
      color: $colorGray90;
      box-shadow: inset 0px -1px 0px $colorBlue30;
      border: none;
      vertical-align: baseline;

      &:hover {
        background-color: $colorGray20;
      }

      .expand-wrapper {
        display: inline-table;
        vertical-align: middle;
        padding: 0 42px 0 16px;
      }
    }

    .ant-table-row-selected,
    .ant-table-row-selected:hover {
      td {
        background-color: $colorBlue20;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: $colorBlue10 !important;
  }

  .ant-table-selection-column {
    padding-right: 0 !important;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $colorGray90;
  }

  .ant-table-pagination.ant-pagination {
    display: grid;
    grid-template-areas: "options total";
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: 25px;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 12px;
    margin: 0;
    padding: 8px 12px;
    color: $colorGray90;
    background-color: $colorGray20;
    box-shadow: inset 0px -1px 0px $colorBlue30;

    @media only screen and (max-width: $screen-sm) {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0;
      justify-content: flex-start;
    }

    &::after {
      display: none;
    }

    li {
      margin: 0;
      @media only screen and (max-width: $screen-sm) {
        order: 2;
      }
    }

    @media only screen and (max-width: 576px) {
      .ant-pagination-options {
        display: inline-block;
      }
    }

    .ant-pagination-options {
      max-width: 119px;
      grid-area: options;
      @media only screen and (max-width: $screen-sm) {
        order: 0;
        max-width: unset;
        width: 40%;
      }

      .ant-row {
        margin: 0 !important;
      }

      .select-default {
        .ant-select.ant-select-in-form-item.ant-select-focused {
          background-color: $colorGray30;
          border: 1px solid $colorBlue70;
          color: $colorGray90;
          border-radius: 4px;
        }

        .ant-select-selector {
          min-height: auto !important;
          height: 32px !important;
          padding: 0 !important;
          border: none;
          background-color: $colorGray20;

          &:hover {
            background-color: $colorGray30;
          }
        }

        .ant-select-selection-item {
          line-height: 20px !important;
          font-size: 14px !important;
          padding: 6px 28px 6px 4px !important;
        }
      }
    }

    .ant-pagination-total-text {
      grid-area: total;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: $screen-sm) {
        order: 1;
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .ant-pagination-item {
      border: none;
      border-radius: 4px;
      height: unset;
      min-height: unset;

      a {
        padding: 2px 6px;
        font-size: 14px;
        line-height: 20px;
        color: $colorGray90;
      }

      &:hover {
        background-color: $colorGray30;
      }

      &-active {
        background-color: $colorGray40;
      }
    }
  }

  .ant-checkbox {
    top: 0;
    border: none !important;

    &::after {
      visibility: hidden !important;
    }
  }

  .ant-checkbox-wrapper {
    align-items: center;
    span {
      font-weight: 400;
    }

    &::after {
      display: none;
    }

    &:hover {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorBlue10;
      }
    }

    &:focus {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorBlue20;
        box-shadow: 0 0 0 2px $colorBlue20;
      }
    }

    &:active {
      .ant-checkbox,
      .ant-checkbox-inner {
        background-color: $colorWhite;
      }
    }
  }

  .ant-checkbox-checked,
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      background-color: $colorBlue70 !important;
      border-color: $colorBlue70 !important;

      &::after {
        left: 23.5%;
        width: 4px;
        height: 6px;
        // left: 16.5%;
        // width: 4px;
        // height: 7px;
        // top: 46%;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-width: 1px;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      width: 93%;
      left: 50%;
      height: 2.5px;
      background-color: $colorWhite;
    }
  }

  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    margin-right: 0;
    border-radius: 2px;
    border: 1px solid $colorGray70 !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: relative;


    &:hover {
      background-color: $colorGray30;
    }

    svg {
      path {
        fill: $colorGray70;
      }
    }
  }

  .ant-pagination-prev.ant-pagination-disabled {
    svg {
      path {
        fill: $colorGray50;
      }
    }
  }

  .ant-pagination-next.ant-pagination-disabled {
    svg {
      path {
        fill: $colorGray50;
      }
    }
  }

  .ant-pagination-prev {
    order: 1;
    left: 6px;

    @media only screen and (max-width: $screen-sm) {
      order: 3 !important;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .ant-pagination-item-link {
        width: 20%;
      }
    }
  }

  .ant-pagination-next {
    order: 2;
    left: 1px;

    @media only screen and (max-width: $screen-sm) {
      order: 4 !important;
    }
  }
}

.ant-table-filter-dropdown {
  border-radius: 0 0 4px 4px !important;
  box-shadow: $boxShadow_1 !important;
  min-width: max-content !important;

  .ant-table-filter-dropdown-search {
    padding: 0;
  }

  .ant-input-affix-wrapper.ant-table-filter-dropdown-search-input {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 22px;
    color: $colorGray100;
    border: none;
    border-bottom: 1px solid $colorBlue40;
    background-color: $colorWhite;
    box-shadow: none;

    &::placeholder {
      color: $colorGray50;
    }
  }

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-title-content {
    align-items: center;
    display: flex;
  }
}

.table-wrapper {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .input-default {
      max-width: 364px;
      width: 100%;
    }
  }

  &__select-title{
    margin-left: 12px;
  }

  &_archive {
    background-color: $colorRed40;
  }

  &__header-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &_collapse-top-margin {
    margin-top: -24px;
  }
}

.disabled-row {
  pointer-events: none;                     
  cursor: not-allowed;
}

.ant-table-cell-row-hover {
  background-color: $colorGray30 !important;
}

.filter-dropdown-table-range {
  width: 560px;
  height: 315px;

  .filter-dropdown-table-range-picker {
    visibility: hidden;
  }
  .rangepicker-filter {
    position: absolute;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    bottom: 0;
    width: 100%;
    height: 48px;

    .ant-space {
      &:nth-of-type(2) {
        position: absolute;
        right: 12px;
        top: 320px;
      }

      .button-default {
        max-height: 32px;
        padding: 6px 12px;
      }
    }
  }
}

.range-picker-custom {
  &.ant-picker-dropdown-range {
    padding: 0;
  }

  &.ant-picker-panel-container {
    box-shadow: none !important;
    border: none;
  }

  &.ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker-panel {
    border: none;
  }
}
