@import "styles/constants";

.hs-users-list {
  &__header {
    padding: 0 24px;
  }

  &__actions {
    column-gap: 8px;
  }

  &__search {
    width: 364px;
  }

  &__users-tabs {
    &.ant-menu {
      background-color: $colorGray10;
    }
  }
}
