@import "src/styles/constants";

.select-multi {
  .ant-select-clear{
    path{
      fill: $colorGray70;
    }
  }
  .ant-select-multiple {
    &.ant-select-status-error{
      .ant-select-selector{
        border-color: $colorRed40 !important;
      }
    }
    .ant-select-selector {
      padding: 5px 11px;

      &::after {
        content: "";
      }
    }

    .ant-select-clear {
      display: flex;
      width: 16px;
      height: 16px;
    }

    .ant-select-item-option-state {
      width: 24px;
      height: 24px;
    }

    .ant-select-arrow {
      height: 16px;
    }

    .ant-select-selection-item-content {
      font-size: 14px;
      line-height: 20px;
    }

    .ant-select-selection-item {
      background-color: $colorBlue70;
      border-radius: 24px !important;
      color: $colorWhite;
      padding: 4px 10px;
      line-height: 20px !important;
      display: flex;
      gap: 10px;
      border: none !important;
      svg path {
        fill: $colorWhite;
      }
      &-disabled{
        background-color: $colorGray50;
      }
      align-items: baseline;
      text-overflow: ellipsis !important;

      .ant-select-selection-item-content {
        max-width: 120px !important;
        text-overflow: ellipsis !important;

        .select-multi_option-text {
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis !important;
        }
      }
    }

    .ant-select-selection-overflow-item-rest {
      .ant-select-selection-item {
        background-color: $colorBlue90;
      }
    }

    &.ant-select-disabled {
      .ant-select-selection-item {
        background-color: $colorGray50;
        color: $colorWhite;
      }

      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item {
          background-color: $colorGray60;
        }
      }
    }
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: $colorGray60;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: $colorBlue70;
  }
}

.rc-virtual-list-holder {
  max-height: 277px !important;
}
.ant-select-selection-overflow-item[aria-hidden="true"] ~ .ant-select-selection-overflow-item-rest{
  opacity: initial !important;
 height: initial !important;
 position: initial !important;
}
