@import "src/styles/constants";

.radio-group-timePeriod-title {
  display: flex;
  align-items: center;
  
  .typography {
    margin-right: 8px;
  }
}

.radio-group-timePeriod {
  &.ant-radio-group {
    padding: 4px;
    border-radius: 4px;
    height: 36px;
    background-color: $colorGray30;

    .ant-radio-button-wrapper {
      background-color: transparent;
      border: unset;
      padding: 4px 8px;
      height: 100%;

      &:focus-within {
        box-shadow: unset;
      }

      &::before {
        display: none;
      }

      span:last-child {
        display: flex;
        height: 100%;
        align-items: center;
      }

      &-checked {
        background-color: $colorWhite;
        color: $colorBlue80;
        border-radius: 4px;
      }
    }
  }
}