@import "src/styles/constants";

.typography {
  font-weight: 400;

  &-headline-1 {
    font-size: 48px;
    line-height: 64px;
  }

  &-headline-2 {
    font-size: 32px;
    line-height: 44px;
  }

  &-headline-3 {
    font-size: 28px;
    line-height: 40px;
  }

  &-headline-4 {
    font-size: 28px;
    line-height: 32px;
  }

  &-headline-d4 {
    font-size: 24px;
    line-height: 32px;
  }

  &-headline-5 {
    font-size: 20px;
    line-height: 28px;
  }

  &-headline-6 {
    font-size: 16px;
    line-height: 24px;
  }

  &-body-1 {
    font-size: 16px;
    line-height: 24px;
  }

  &-body-2 {
    font-size: 14px;
    line-height: 20px;
  }

  &-button {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }

  &-caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &-label {
    font-size: 12px;
    line-height: 16px;
  }

  &.bold {
    font-weight: 700;
  }

  &.semi-bold {
    font-weight: 600;
  }

  &.medium {
    font-weight: 500;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }

  &.italic {
    font-style: italic;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.typography_indent_title {
  margin: 27px 0 16px 0;
}
