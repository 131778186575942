@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat-list-feature-list {
  padding: 4px 0;
  @media only screen and (max-width: $screen-sm) {
    max-height: calc(100dvh - 184px);
  }
}
.chat-list-feature-list__spinner {
  padding: 12px 0;
  font-size: 24px;
}

.empty-chat{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &__text{
    color: $colorGray80;
  }
}