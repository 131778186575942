@import "src/styles/constants";

.cognus-repository-container{
  &__header{
    align-items: baseline;
    &__btn{
      gap: 8px;
      align-items: baseline;
    }
    .ant-radio-button-wrapper{
      height: 40px;
    }
    .requests-for-analysis-btn{
      &.radio-group-buttons-wrapper{
        .ant-radio-group{
          border-radius: 4px !important;
          .ant-radio-button-wrapper{
            border-right-color: $colorGray30 !important;
          }
        }
      }
    }
  }
}