@import "src/styles/constants";

.switch-default{
    min-width: 36px !important;
    height: 16px !important;
  &.ant-switch-checked{
    .ant-switch-handle {
      left: calc(100% - 14px - 2px)
    }
  }
  .ant-switch-handle{
    width: 14px;
    height: 14px;
    top: 1px;
  }
}