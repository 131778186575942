@import "src/styles/constants";
.vendor-info-form {
  max-width: 752px;
  &_address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 24px;
  }

  &_phone {
    margin-top: 24px;
    max-width: calc(50% - 12px);
  }
  &_buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
  }

  .input-default {
    margin-bottom: 24px !important;
  }

  &_buttons_hide {
    display: none;
  }
  .vendor-permissions-view{
    margin-bottom: 24px;
    .typography-label{
      color: $colorGray70;
      margin-bottom: 4px;
    }
    .typography-body-1{
      span{
        color: $colorGray70;
      }
    }
  }
}
