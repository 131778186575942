@import "src/styles/constants";

.custom-steps {
  width: 304px !important;
  height: 42px !important;

  .ant-steps-item,
  .ant-steps-item-description,
  .ant-steps-item-container,
  .ant-steps-item-content {
    width: 76px !important;
  }

  .ant-steps-item-description {
    font-size: 12px;
    margin-top: 6px;
    text-align: left;
  }

  .ant-steps-item-tail {
    top: 0 !important;
    margin: 0 0 0 26px !important;

    &::after {
      height: 20px !important;
      margin-left: unset !important;
      width: calc(100% - 2px) !important;
    }
  }

  .ant-steps-item-icon {
    margin: 0 0 0 14.5px !important;

    .ant-steps-icon-dot {
      width: 20px !important;
      height: 20px !important;
      border: 1.4px solid $colorGray40;
      transition: unset !important;

      &::before {
        position: absolute;
        content: url("../../assets/svg/Check.svg");
        left: 5px;
        top: -1px;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-icon-dot {
      background-color: $colorBlue70 !important;
      border: 1.4px solid $colorBlue70;
    }

    .ant-steps-item-description {
      color: $colorGray90 !important;
    }
  }

  .ant-steps-item-active {
    .ant-steps-icon-dot {
      background-color: $colorWhite !important;
      border: 1.4px solid $colorBlue70;
      margin-top: 1px;
    }

    .ant-steps-item-description {
      color: $colorBlue70 !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-icon-dot {
      background-color: $colorWhite !important;
    }

    .ant-steps-item-description {
      color: $colorGray50 !important;
    }
  }

  .ant-steps-item-active,
  .ant-steps-item-wait {
    .ant-steps-item-tail {
      &::after {
        background-color: $colorGray20 !important;
      }
    }
  }
}
