@import 'src/styles/constants';

.search-component{
  max-height: 56px;
  padding: 12px 32px 12px 24px;
  border-bottom: 1px solid $colorGray30;
  gap: 10px;
}

.search-component-input{
  width: 100%;
 .custom-input{
    &.mt-24 {
      margin-top: 0 !important;
    }
  }
  .ant-input-affix-wrapper{
    max-height: 32px;
  }
}

.search-component-btn{
    .custom-button{
      max-height: 32px;
    }
}

.search-component-counter{
  color: $colorGray70;
  user-select:none;
}

.search-component-arrow-up, .search-component-arrow-down{
  padding: 8px;
  path{
    fill: $colorBlue70;
  }
  &:hover{
    cursor: pointer;
  }
  &.disabled{
    path{
      fill: $colorGray50;
    }
    &:hover{
      cursor: not-allowed;
    }
  }
}
.search-component-arrow-up{
    svg{
      transform: rotate(180deg);
    }
}
