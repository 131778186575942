@import "src/styles/breakpoint";
@import "src/styles/constants";

.rfp-details-general {
  position: relative;
  display: flex;

  @media only screen and (max-width:$screen-md) {
   flex-direction: column;
    gap: 24px;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__left {
    max-width: 752px;
  }

  &__right {
    margin-left: 24px;
    max-width: 364px;
    @media only screen and (max-width:$screen-md) {
      margin-left: 0;
    }
  }
  .participation-type-view, .rfp-type-view{
    max-width: 267px;
    width: calc(50% - 8px);
    .typography-label{
      margin-bottom: 4px;
    }
  }

  &__chips-wrapper {
    .typography-label {
      margin-bottom: 0;
    }

    .custom-chips {
      margin-top: 8px;

      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }
  }

  &__files {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width:$screen-sm) {
      flex-direction: column;
      gap: 24px;
    }

    &__label{
      color: $colorGray70;
    }
    &__wrapper{
      display: flex;
      gap: 25px;
      align-items: center;
      div:first-child {
        min-width: 165px;
      }
      &__upload{
        button{
          padding-left: 0 !important;
        }
      }
      .typography{
        margin-bottom: 0 !important;
      }
      .attachment__content{
        span{
          font-weight: 400;
        }
      }
      &__template{
        .attachment__content{
          &_hover{
            span{
              color: $colorBlue80;
            }
          }
          span{
            max-width: 137px !important;
            color: $colorBlue70;
            font-weight: 500;
          }
        }
      }
    }
    .rfp-details-general__files__title__file{
      &.attachments-list{
        width: 100%;
      }
    }
    &__title{
      border-bottom: 1px solid $colorBlue20;
      max-width: 558px;
      padding-bottom: 4px;
      .typography-label{
        margin-bottom: 0;
        font-size: 14px !important;
      }
      &__attachments{
        max-width: 364px;
        .attachment{
          .attachment__content{
            span{
              max-width: 300px;
              font-weight: 400;
            }
          }
        }
      }
      &.without-border{
        border-bottom: none;
      }
      .typography-label{
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $colorGray90;
      }

      &__desc{
        display: flex;
        align-items: center;
        color: $colorGray70;
        max-width: 169px;
      }
    }

    &-wrapper {
      width: calc(50% - 12px);
    }

    &-left {
      display: flex;
      flex-direction: column;

      .attachments-list {
        &:first-of-type {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    max-width: 752px;
    .custom-button {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  &__community-info{
    margin-bottom: 24px;
  }
}
