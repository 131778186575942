@import "src/styles/constants";
@import "src/styles/breakpoint";

.radio-group-default-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  @media only screen and (max-width:$screen-sm-max) {
    flex-direction: column;
    align-items: flex-start;
  }

}
.radio-group-default {
  .ant-radio-disabled + span {
    color: $colorGray90;
  }
  &.ant-radio-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 10px;
    .ant-space-vertical{
      gap: 16px !important;
    }
    @media only screen and (max-width:$screen-md) {
      padding: 0;
    }

    @media only screen and (max-width:$screen-sm-max) {
      flex-direction: column;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-radio-wrapper {
    margin-right: 0;
    display: flex;
    align-items: center;
    @media only screen and (max-width:$screen-sm-max) {
      margin: 0;
    }

    .ant-radio-inner {
      width: 21px;
      height: 21px;
      background-color: transparent;
      border-width: 1.5px;
      border-color: $colorGray70;

      &::after {
        transform: scale(.7);
      }
    }
  }
  &__input{
    .ant-radio-wrapper{
      align-items: baseline;
    }
  }
}


.radio-group__default--error {
  .ant-radio-inner {
    border-color: $colorRed40 !important
  }
}
