@import "src/styles/constants";

.user-archive_text {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.user-archive_buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  max-height: 40px;
  align-items: baseline;
  margin-bottom: 32px;
}

