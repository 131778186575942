@import "src/styles/constants";

.button-icon {
  &.custom-button {
    &.ant-btn {
      padding: 11px;

      &.ant-btn-dangerous,
      &.ant-btn-dangerous:hover,
      &.ant-btn-dangerous:focus,
      &.ant-btn-dangerous:active {
        &:disabled {
          svg path {
            fill: $colorGray50;
          }
        }

        svg path {
          fill: $colorRed40;
        }
      }
    }

    &.ant-btn-link {
      display: inline-flex;
      border-radius: 2px;

      &:hover {
        background-color: $colorGray30;
      }
    }

    &.small {
      height: 24px;
      padding: 0;
      width: 24px;
    }

    &.medium {
      height: 32px;
      width: auto;
      padding: 8px;
    }
  }
}
