@import "src/styles/constants";

.attachments-list {
  .show-version-history-button {
    margin-top: 0;
    justify-content: flex-start;
  }
}
.attachments-list--with-separator {
  &::after {
    content: '';
    display: flex;
    height: 1px;
    margin: 8px 0;
  }
}
