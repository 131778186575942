@import "src/styles/constants";

.contract-timeline-form {
  display: flex;
  flex-direction: column;
}

.contract-details-timeline-popup{
  .ant-modal-body{
    overflow-y: inherit !important;
  }
}

.prs-steps-modal-text{
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid $colorBlue30;
  margin-bottom: 16px;
}