.community-info-form {
  max-width: 752px;

  &_buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
  }

  .input-default {
    margin-bottom: 24px !important;
  }

  &_buttons_hide {
    display: none;
  }
}