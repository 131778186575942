@import "src/styles/constants";
@import "src/styles/breakpoint";

.check-ins-container {
  display: grid;
  grid-template-columns: 396px 1fr;
  height: 100vh;
  overflow: hidden;
  @media only screen and (max-width: $screen-md) {
    height: calc(100dvh - 68px);
  }
  @media only screen and (max-width: $screen-sm) {
    height: calc(100dvh - 56px);
    overflow-x: hidden;
  }
  &__list {
    border: 1px solid $colorGray30;
    height: inherit;
    &__item {
      &:hover {
        cursor: pointer;
        background-color: $colorBlue10;
      }
      &.selected{
        background-color: $colorBlue10;
      }
      .name-container{
        display: block;
      }
      &__list {
        .check-ins-container__list__group-header {
          display: block;
          padding: 8px 32px;
          top: 0;
          background-color: $colorGray20;
          .typography {
            color: $colorGray90;
          }
        }
      }
      &-left {
        gap: 12px;
      }
      .date-time-container {
        text-align: right;
        color: $colorGray70;
        min-width: 130px;
      }
      &.icon-name {
        padding: 12px 20px 12px 32px;
        gap: 0;
        &.active:not(:has( + div.check-ins-container__list__group-header)), &.inactive {
          border-bottom: 1px solid $colorGray30;
        }
        .name-container {
          gap: 0;
        }
        .status-icon {
          top: 29px;
          right: 0;
        }
      }
    }
    &__header {
      .ant-btn {
        height: 40px;
      }
      &__filter {
        width: 107px;
      }
      &__filters {
        gap: 8px;
      }
    }
    &-empty {
      height: 100%;
      flex-direction: column;
      color: $colorGray80;
      text-align: center;
      &__icon {
        margin-bottom: 8px;
      }
    }
  }
  &__details {
    height: inherit;
  }
}

.check-ins__filter-modal {
  .ant-modal-body {
    overflow: visible;
  }
}

