@import 'src/styles/constants';
.forms-sign-up {
  .custom-link {
    font-size: 14px;
    line-height: 20px;
  }
}

.terms-links{
  span{
    font-size: 14px;
    line-height: 20px;
    color: $colorBlue70;
    &:hover{
      cursor: pointer;
    }
  }
}
