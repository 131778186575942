@import "src/styles/constants";
@import "src/styles/breakpoint";

.chats-grid {
  display: grid;
  grid-template-columns: 396px 1fr;
  height: 100vh;
  overflow: hidden;
  .chat-header-arrow-back-wrapper{
    display: none;
    svg{
      width: 24px;
      height: 24px;
    }
  }
  @media only screen and (max-width: $screen-md) {
    grid-template-columns: 272px 1fr;
    height: calc(100dvh - 68px);
  }
  @media only screen and (max-width: $screen-sm) {
    height: $height-sm;
    .chats-grid__chat{
      display: none;
    }
    .chat-list-feature-item__caption{
      margin-top: 0;
    }
    &.open-chat{
      .chats-grid__chat{
        width: 100vw;
        max-width: 100vw;
        display: initial;
      }
      .chats-grid__list{
        display: none;
      }
      .chat-header-arrow-back-wrapper{
        display: flex;
        align-items: center;
      }
    }
    .chats-grid__list{
      width: 100vw;
      display: initial;
    }
  }

}
.chats-grid__list {
  border-right: 1px solid $colorGray30;
  overflow: hidden;
}
