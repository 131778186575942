.vendor-selection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-left: 11px;

  .custom-select {
    width: 490px;
  }
}