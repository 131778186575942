.ant-form {
  .row {
    display: flex;
    width: 100%;
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }

    &.mt-24 {
      margin-top: 24px;
    }

    &.mt-32 {
      margin-top: 32px;
    }

    &.vertical {
      flex-direction: column;
    }

    &.centered {
      justify-content: center;
    }

    .button-default.ant-btn-primary {
      width: 100%;
    }

    .ant-row {
      width: 100%;
      flex-direction: column;
    }

    &.two-columns {
      .ant-row {
        width: calc(50% - 8px);

        &:first-of-type {
          margin-right: 16px;
        }

        &.column-half {
          width: 25%;
        }
      }
    }
  }

  .form-subtitle {
    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }
  .forgot-password-footer{
    max-height: 20px;
  }
}
