.extend-contract-form__actions {
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  justify-content: flex-end;

  & > button:not(:last-child) {
    margin-right: 16px;
  }
}
