@import "src/styles/constants";

.opportunity-analysis-commons-container{
  .ant-collapse-content, .ant-collapse-content-box{
    background-color: $colorWhite !important;
    &:hover{
      cursor: pointer;
      background-color: $colorGray10 !important;
    }
  }
  &__community-wrapper{
    border-bottom: 1px solid $colorBlue20;
    border-left: 1px solid $colorBlue20;
    border-right: 1px solid $colorBlue20;
    padding: 16px 24px;
    .contract-category-wrapper{
      border-radius: 4px;
      background-color: $colorGray30;
      padding: 4px 6px;
    }
  }
}