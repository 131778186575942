@import "src/styles/constants";
@import "src/styles/breakpoint";

.container {
  height: 100%;
  padding: 24px 32px;
  //overflow-y: scroll;
  //width: 164vh;
  overflow-y: auto;
  max-width: calc(100vw - 236px);
  @media only screen and (max-width: $screen-sm) {
    padding: 24px 16px;
    height: 100dvh;
    min-height: fit-content;
  }

  @media only screen and (max-width: $screen-md) {
    max-width: 100vw;
  }

  &__button{
    span{
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    @media only screen and (max-width:$screen-sm) {
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
    }
  }

  &__status {
    display: flex;
    align-items: center;

    .custom-button {
      &.ant-btn-link {
        margin-right: 12px;
        color: $colorGray90;
      }
      &.container__timer-button--pointer-events_none {
        pointer-events: none;
      }
    }
  }

  &__actions-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
    @media only screen and (max-width:$screen-sm-max) {
      display: none;
    }
    &__mobile{
      display: none;
      @media only screen and (max-width:$screen-sm-max) {
        display: flex;
        justify-content: flex-end;
      }
    }


    .custom-button {
      &:not(:first-of-type) {
        margin-left: 8px;
      }
    }
  }

  &__subtitle {
    margin-top: 4px;
    color: $colorGray70;
  }

  &__menu {
    margin-top: 24px;
  }

  &__content {
    margin-top: 24px;
    @media only screen and (max-width: $screen-md) {
      height: 100%;
    }
    @media only screen and (max-width: $screen-sm-max) {
      min-height: fit-content;
      height: auto;
    }
  }

  &__title{
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    @media only screen and (max-width: $screen-sm) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
