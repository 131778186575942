.table-number-cell {
  &.ant-input-number {
    input {
      padding: 0 !important;
      border: none;
    }
  }

  &--with-error {
    &.ant-input-number {
      background-color: transparent;

      input {
        background-color: transparent;
      }
    }
  }
}
