@import "styles/constants";
.prefix-actual-savings{
  line-height: 22px !important;
  color: $colorGray70;
}

.edit-actual-savings-container{
  .ant-modal-body{
    max-height: 500px;
  }
  .custom-input{
    max-width: 174px;
  }
  .actual-savings-period-row{
    align-items: flex-start !important;
  }
  .actual-savings-period{
    .typography-body-1{
      font-weight: 700;
    }
    .field-with-label{
      width: 80px;
      .typography{
        margin-bottom: 14px;
      }
    }
  }
}

.savings-history-empty {
  min-height: 300px;
}
.savings-history-empty__text {
  color: $colorGray50;
  text-align: center;
}

.prs-history-item__acton-title__user {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}