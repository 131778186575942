@import "src/styles/constants";
.empty-state {
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  place-content: center;
  align-items: center;

  &_svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}