@import "src/styles/constants";

.acceptance-status {
  margin-right: 16px;

  &--accepted {
    color: $colorGreen40;
  }

  &--declined {
    color: $colorRed40;
  }
}
