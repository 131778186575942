@import "src/styles/constants";

.departments-management {
  &_open-btn svg {
    path {
      fill: $colorBlue70;
    }
  }

  &_department-container {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $colorBlue10;
    padding: 8px 12px;
    height: 40px;
    justify-content: space-between;

    svg path {
      fill: $colorGray70;
    }
  }

  &_department-buttons {
    display: flex;
    gap: 10px;
  }

  &_add-btn {
    svg path {
      fill: $colorBlue70;
    }
  }

  &_departments-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }
}

