@import "src/styles/constants";

.timeline {
  display: grid;
  grid-template-columns: 1fr auto auto minmax(80px, auto) minmax(40px, auto);
  margin-bottom: 8px;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.timeline__date-picker.ant-form-item {
  width: 160px;
}

.timeline__time-selector.ant-form-item {
  width: 140px;
}

.timeline__time-selector,
.timeline__date-picker {

  .ant-picker {
    border-radius: 4px;
    max-height: 40px;
  }
  .ant-picker-input{
    input{
      font-size: 16px;
      line-height: 24px;
    }
  }

  .ant-picker.ant-picker-disabled {
    background: $colorGray10;
    border-color: $colorGray40;
  }

  .ant-picker-input > input[disabled] {
    color: inherit;
  }
}

.timeline__duration {
  color: $colorGray70
}

.timeline__item {
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
}

.timeline__reset-action.custom-button.ant-btn.ant-btn-default {
  border: 0;
}
.single-with-check{
  .ant-select-item-option-state{
    height: 24px;
    display: flex;
    align-items: center;
    svg{
      path{
        fill: $colorBlue70 !important;
      }
    }
  }
}