@import "styles/constants";

.request-for-analysis-container{
  &__community-wrapper{
    border-bottom: 1px solid $colorBlue20;
    border-left: 1px solid $colorBlue20;
    border-right: 1px solid $colorBlue20;
    border-top: 1px solid $colorBlue20;
    padding: 16px 24px;
    background-color: $colorWhite !important;
    .contract-category-wrapper{
      border-radius: 4px;
      background-color: $colorGray30;
      padding: 4px 6px;
    }
    &:hover{
      cursor: pointer;
      background-color: $colorGray10 !important;
    }
  }
  &__community-empty-state{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
  }
}