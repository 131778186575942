@import "src/styles/constants";
@import "src/styles/breakpoint";

.create-survey-prs-container{
  &__btn-wrapper{
    max-width: 460px;
    width: 460px;
    padding: 32px 24px;
    background-color: $colorBlue10;
    border: 1px solid $colorBlue20;
    border-radius: 7px;
    @media only screen and (max-width: $screen-sm) {
      max-width: calc(100vw - 32px);
      width: calc(100vw - 32px);
    }
    button{
      max-width: 137px;
      @media only screen and (max-width: $screen-sm) {
        padding: 24px 16px;
      }
    }
  }
}

.prs-modal-create-survey{
  .ant-modal-footer{
    padding: 16px 32px 0;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0 0 8px 8px;
    margin-left: -32px;
    width: 680px;
  }
}
