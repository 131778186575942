@import "src/styles/constants";
@import "src/styles/breakpoint";
.prs-details-general{
  max-width: 752px;
  width: 752px;
  &__vcs{
    max-width: 364px;
    width: 100%;
  }
  @media only screen and (max-width: $screen-md) {
    width: calc(100vw - 64px);
    max-width: calc(100vw - 64px);
  }
  @media only screen and (max-width: $screen-sm) {
    width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
  }
  &__left{
     .prs-details-general__left__contract-category{
       @media only screen and (max-width: $screen-md) {
         max-width: 240px;
       }
     }
    @media only screen and (max-width: $screen-md) {
      .two-columns{
        display: flex;
        flex-direction: column;
        &.prs-creation-form__half-row{
          margin-top: 24px !important;
          width: calc(100vw - 32px) !important;
          .field-with-label{
            &:nth-of-type(2){
              margin-top: 24px !important;
            }
          }
        }
      }
      .prs-details-general__left__contract-category{
        max-width: calc(100vw - 32px);
        width: calc(100vw - 32px);
      }
    }
  }
  &__left, &__right{
    max-width: inherit;
    width: 100%;
    .two-columns{
      .field-with-label{
        width: calc(100% / 2 - 12px);
      }
    }
    .field-with-label{
      width: 100%;
    }
  }
  .product_pictures_title{
    &.creation{
      .attachments-list__files{
        &.images{
          gap: 16px;
        }
        &__container{
          margin-bottom: 8px !important;
        }
      }
      .typography {
        &:nth-of-type(1){
          &::after {
            content: '*';
            color: $colorRed40;
          }
        }
      }
    }

  }
  .file-upload-wrapper{
    position: relative;
    display: inline-block;
    cursor: not-allowed;
  }
  .attachments-list__files{
    margin-bottom: 0 !important;
  }
}

.prs-details-general-container{
  width: 100%;
  @media only screen and (max-width: $screen-md) {
    display: flex;
    flex-direction: column !important;
  }
  .prs-details-general__left__contacts{
    max-width: 364px;

    @media only screen and (max-width: $screen-md) {
      margin-left: 0;

    }
    &__vendor{
      @media only screen and (max-width: $screen-md) {
        margin-top: 24px;
      }
    }
  }
  .rfp-details-responsible-user{
    max-width: 364px;
  }
}