@import "src/styles/constants";
.members-container{
  padding-top: 32px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.members-container__list {
  width: 100%;
  &__more{
    color: $colorGray60;
  }
}
