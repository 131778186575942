.vendor-details__form {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}
.vendor-details-form__actions {
  display: flex;
  align-self: end;
  column-gap: 16px;
}
