.tooltip-default {
  &.ant-tooltip-placement-topLeft{
    padding: 0;
  }
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background: rgba(31, 36, 41, 0.6);
      backdrop-filter: blur(3px);
      border-radius: 6px;
      padding: 4px 8px;
      position: relative;
      top: 5px;
    }
  }

  &__wrapper {
    display: inline-block;
  }
}