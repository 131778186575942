@import "styles/constants";

.opportunity-analysis-container{
  &__empty-state{
    &__wrapper{
      max-width: 461px;
      text-align: center;
    }
    margin-top: 88px;
    display: flex;
    justify-content: center;
  }
  .clinical-review-results{
    span{
      display: inline-flex;
      border-radius: 18px;
      overflow: hidden;
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.contact-persons-wrapper{
    &__led-by{
     .contact-persons-wrapper__led-by__wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .ant-avatar{
          width: 32px;
          height: 32px;
          .ant-avatar-string{
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
}

.total-saving-wrapper{
  width: 100%;
  &__item{
    padding: 8px 60px 8px 0;
    width: 100%;
    justify-content: flex-end;
    border-bottom: 1px solid $colorBlue30;
    .amount{
      max-width: 120px;
      width: 120px;
      margin-left: 40px;
      text-align: right;
    }
  }
}