@import 'src/styles/constants';

.drawer-default {
  padding: 0;

  .ant-drawer-mask{
    opacity: 0 !important;
  }
  .ant-drawer-header{
    border: none;
    padding: 24px 32px 8px 32px;
  }
  .ant-drawer-body {
    padding: 32px;
  }
  .ant-drawer-header-title{
    display: flex;
    flex-direction: column;

    .ant-drawer-close{
      display: flex;
      width: 100%;
      margin-right: 0;
      justify-content: flex-end;
    }

    .ant-drawer-title{
      width: 100%;
      text-align: left;
      color: $colorGray90;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .ant-drawer-content-wrapper{
    width: 396px !important;
    border-radius: 8px 0 0 8px;
    overflow: hidden;
  }
}

