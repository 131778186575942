@import "src/styles/constants";

.dropdown-filter {
  &__options {
    max-height: 160px;
    overflow: auto;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 12px;

    .ant-btn {
      padding: 6px 12px;

      &:first-of-type {
        margin-right: 8px;
      }
    }
  }

  .ant-spin {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-input-affix-wrapper {
    border: none;
    border-bottom: 1px solid $colorBlue20;
    border-radius: 0 !important;

    &:hover,
    &:focus,
    &:active,
    &-focused {
      border-color: $colorBlue20 !important;
    }
  }

  .checkbox-default {
    padding: 8px 12px;

    .ant-form-item-control-input-content {
      height: 24px !important;
      white-space: nowrap;
      background-color: $colorWhite !important;
    }
  }
}


@media not all and (min-resolution: 0.001dpcm) {
  .dropdown-filter {
    .checkbox-default {
      padding: 5px 12px;
      .ant-form-item-control-input-content {
        height: unset !important;
      }
    }
  }
}