@import "src/styles/constants";

.float-footer {
  position: fixed;
  z-index: 2;
  bottom: 16px;
  width: calc(100% - 300px) !important;
  padding: 8px;
  background-color: $colorWhite;
  border: 1px solid $colorGray30;
  border-radius: 8px;
  box-shadow: 0 4px 6px 2px rgba($colorGray80, 0.12);

  button.ant-btn.button-default.custom-button {
    width: auto;
  }

  @media only screen and (max-width: 991px) {
    width: calc(100% - 64px) !important;
  }
}
