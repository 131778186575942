@import "src/styles/constants";
@import "src/styles/breakpoint";
@import "src/styles/mixins";
.prs-details-participants{
    .rfp-details-responsible-user{
      max-width: 364px;
      margin-top: 4px;
    }
}
.prs-details-participants{
  .prs-details-participants-container{
    @media only screen and (max-width: $screen-md) {
      display: flex;
      flex-direction: column !important;
    }
    .prs-details-participants__left__part__wrapper{
      max-width: 752px;
      width: 100%;
    }
    .prs-details-participants__left__part, .clinical-sponsor-info{
      @media only screen and (max-width: $screen-md) {
        display: flex;
        flex-direction: column !important;
        .prs-details-participants__left__community-user{
          margin-top: 24px;
          margin-left: 0 !important;
        }
        .clinical-sponsor-action{
          margin-top: 24px;
        }
      }
    }
  }
  &__left{
    width: 752px;
    @media only screen and (max-width: $screen-md) {
      width: calc(100vw - 64px);
    }
    @media only screen and (max-width: $screen-sm) {
      width: calc(100vw - 32px);
      .row{
        &.two-columns{
          display: flex;
          flex-direction: column !important;
          row-gap: 24px;
        }
      }
    }
    .two-columns{
      .field-with-label{
        width: calc(100% / 2 - 12px);
      }
    }
    .field-with-label{
      width: 100%;
    }
    &__community-user{
      max-width: 364px;

      &.clinical{
        margin-top: 72px;
      }
    }
  }
}
.responsible-popup{
  .ant-modal-body {
    overflow-y: visible !important;
  }
}

.clinical-rationale-container{
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
  padding: 32px 24px;
  row-gap: 16px;
  max-width: 679px;
  .ant-btn-primary{
    max-width: 125px;
  }
  .ant-btn-default{
    max-width: 170px;
  }
}

.prs-modal-cs-survey{
  .ant-modal-footer{
    padding: 16px 32px 0;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0 0 8px 8px;
    margin-left: -32px;
    width: 680px;
    .mobile-text{
      display: none;
    }
  }
  .prs-modal-create-survey__footer{
    width: 100%;
    &__actions{
      .prs-modal-create-survey__footer__actions__page-number{
        margin-right: 16px;
        color: $colorGray70;
        @media only screen and (max-width:$screen-sm-max) {
          display: none;
        }
        &__mobile{
          display: none;
          @media only screen and (max-width:$screen-sm-max) {
            display: flex;
            color: $colorGray70;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $screen-md) {
    @include mobileModal;
    .ant-modal-content{
      max-height: 100dvh;
      height: 100%;
      border-radius: 0;
      .ant-modal-body{
        overflow-y: auto !important;
        min-height: auto;
        max-height: calc(100dvh - 180px);
      }
    }
    .ant-modal-footer{
      max-width: 100vw;
      margin-left: 0;
      .btn-text{
        display: none;
      }
      .mobile-text{
        display: inline;
      }
    }
  }
}

.responsible-prs-users-wrapper{
  .typography-body-1{
    display: flex;
    flex-direction: column;
    gap: 12px
  }
}