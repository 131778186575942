.infinite-list-container {
  height: 100%;
  overflow: auto;
}
.infinite-list__sentry {
  display: flex;
  justify-content: center;
  align-items: center;
}
.infinite-list__sentry--fill {
  height: 100%;
}
