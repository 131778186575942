@import "src/styles/constants";

.datepicker-default {
  .ant-picker {
    width: 100%;
  }
  .ant-picker:hover, .ant-picker-focused{
    border: 1px solid $colorGray60;
    border-radius: 4px;
  }
  .ant-picker-focused{
    box-shadow: none;
    border: 1px solid $colorBlue70;
    border-radius: 4px;
  }
  &.ant-form-item-has-error {
    .ant-form-item-explain-error {
      font-style: italic;
      font-weight: 600;
      font-size: 13px;
    }
  }
  &.custom-picker-error {
    .ant-picker {
      background: $colorRed4;
      border-color: $colorRed40 !important;
      border-radius: 0;
    }
  }
}
.datepicker-dropdown{
  .ant-picker-panel-container{
    box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: $colorBlue70;
    border-radius: 2px;
    font-weight: 600;
    font-size: 12px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    background: $colorWhite;
    color: $colorGray90;
    font-size: 12px;
    &::before {
      border: none;
    }
  }
  .ant-picker-header {
    border: none;
  }
  .ant-picker-header-view {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $colorGray90;
  }
  .ant-picker-cell-disabled::before {
    background: none;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    font-size: 12px;
    color: $colorGray50 !important;
  }
  .ant-picker-today-btn {
    display: none;
  }
  .ant-picker-footer {
    border: none;
    min-width: 0;
  }
  .ant-picker-content th {
    font-size: 12px;
    line-height: 16px;
    color: $colorGray70;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-view {
    font-size: 12px;
    line-height: 16px;
    color: $colorGray90;
  }
  .ant-picker-cell {
    line-height: 16px;
    font-size: 12px;
    color: $colorGray50;
  }
  .ant-picker-header-prev-btn {
    position: absolute;
    left: 8px;
    top: 0;
  }
  .ant-picker-header-next-btn {
    position: absolute;
    right: 8px;
    top: 0;
  }
}
