.community-users-table {
  td.ant-table-cell {
    vertical-align: middle;

    &.avatar-cell {
      padding-right: 0;

      .ant-avatar-group {
        display: block;
      }
    }
  }
}
