@import '../../../../../styles/constants';

.contract-extension-history {
  display: flex;
  flex-direction: column;

  .contract-extension-history__document:last-child {
    .contract-extension-history__node::after {
      display: none;
    }
  }
}
.contract-extension-history__document {
  position: relative;
  display: flex;
  flex-direction: row;

  .attachment {
    width: 100%;
  }
}
.contract-extension-history__node {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 19px;
  transform: translateY(15px);

  &::before {
    content: '';
    display: block;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    background-color: $colorBlue30;
  }

  &::after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background-color: $colorBlue30;
  }
}
.contract-extension-history__date {
  min-width: 153px;
  margin-right: 24px;
  padding: 8px 0;
}
