@import "../../../../../styles/constants";

.rfp-details-responsible__user-list {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  max-width: 364px;
  width: 100%;
}
.rfp-details-responsible-user {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
.rfp-details-responsible-user__user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rfp-details-responsible-user__avatar {
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.rfp-details-responsible-user__image {
  height: 100%;
}
.rfp-details-responsible-user__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rfp-details-responsible-user__role {
  color: $colorGray60
}
