@import 'src/styles/constants';
@import 'src/styles/breakpoint';

.chat-person {
  &_icon-name {
    display: flex;
    flex-direction: row;
    gap: 12px;

  }

  &_name-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .typography-caption {
      color: $colorGray60 !important;
    }
  }
}

.start-pvp-chat {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;

  &_users-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
  }

  .ant-input-prefix {
    //scale: 0.7;
  }
}

.chat-creation-modal{
  .ant-drawer-content-wrapper{
    @media only screen and (max-width: $screen-md) {
      width: calc(100vw - 272px) !important;
      .custom-input{
        max-width: 408px;
      }
    }
    @media only screen and (max-width: $screen-sm) {
      border-radius: 0;
      width: 100vw !important;
    }
  }
}