@import "src/styles/constants";

.market-share-rebate {
  &__hospital-option {
    .ant-form-item-label {
      line-height: 16px;
      text-align: left;
      padding-bottom: 4px;

      label {
        height: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorGray90;
      }
    }
  }

  &__hospital-rebate {
    position: relative;
  }

  &__remove-hospital-btn.ant-btn {
    position: absolute;
    bottom: 0;
    left: calc(100% + 12px);
  }

  &__add-btn {
    width: 156px;
  }

  .row {
    margin: 0;
  }
}
