@import "styles/constants";

.contacts-hs-list-table {
  .ant-table-measure-row {
    display: table-row !important;
  }

  .warning-highlight {
    background: $colorYellow10-2;
  }

  .ant-table-header table {
    table-layout: fixed !important;
  }
}
