@import "src/styles/constants";

.datepicker-range{
  .ant-picker {
    position: relative;
    width: 260px;
    padding: 8px 14px 8px 12px;
    .ant-picker-input:nth-of-type(3) {
      padding-left: 14px;
    }
  }
  .ant-picker:hover{
    border-color: $colorGray60;
    .datepicker__separator-line {
      background-color: $colorGray60;
    }
  }

  .ant-picker-focused {
    box-shadow: none;
    border-color: $colorBlue70;
    .datepicker__separator-line {
      background-color: $colorBlue70;
    }
  }

  .anticon-calendar svg {
    fill: $colorGray70;
  }
  .ant-picker-range-separator {
    padding-left: 0;
    padding-right: 14px;
  }

  .datepicker__separator {
    display: flex;
  }

  .datepicker__separator-line {
    position: absolute;
    display: block;
    left: 128px;
    top: 0;
    height: 38px;
    width: .8px;
    background-color: $colorGray40;
  }
  .ant-picker-clear {
    right: 14px !important;
  }

  .ant-picker-active-bar {
    display: none;
  }
}