@import "src/styles/constants";
@import "src/styles/breakpoint";

@mixin circleFonts{
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  -moz-border-radius:  50%;
  -webkit-border-radius: 50%;
}

@mixin mobileModal{
  @media only screen and (max-width:$screen-sm-max) {
    height: 100vh;
  }
  .ant-modal-body {
    overflow-y: visible !important;
  }
  .ant-modal-content{
    padding: 0;
  }
  @media only screen and (max-width:$screen-sm-max) {
    &.ant-modal{
      margin: 0 !important;
      max-width: 100vw !important;
    }
    .ant-modal-body.ant-modal-body {
      overflow-y: scroll !important;
      min-height: calc(100vh - 200px);
      padding: 0 16px 16px;
    }
  }

  .ant-modal-header{
    padding: 48px 32px 24px;
  }

  .ant-modal-body{
    padding: 0 32px 16px;
    margin-right: 0 !important;
  }
  .ant-modal-footer{
    padding: 16px 32px 32px;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0px 0px 8px 8px;
  }
}