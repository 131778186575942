@import "src/styles/constants";

.error-banner-with-title {
  row-gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;
  background-color: $colorRed4;

  &__icon {
    height: 20px;

    svg {
      height: 16px;
      width: 16px;

      path {
        fill: $colorRed50;
      }
    }
  }
}