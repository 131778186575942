@import "src/styles/styles";

.custom-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $colorBlue70;

  &:focus,
  &:hover {
    color: $colorBlue80;
  }

  &:active {
    color: $colorBlue90;
  }

  &:disabled {
    color: $colorGray60;
  }
}
