.contract-details-preview {
  display: flex;
  flex-direction: column;

  &__container {
    display: grid;
    grid-template-columns: minmax(auto, 364px) minmax(auto, 360px);
    grid-column-gap: 24px;
  }

  &__grid {
    display: grid;
    grid-template-rows: repeat(3, 48px);
    grid-row-gap: 24px;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 170px));
    grid-column-gap: 24px;
  }
}
