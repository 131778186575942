@import "src/styles/constants";
.terms-subtitle{
  span{
    font-weight: 600;
  }
  a{
    color: $colorBlue80;
    &:hover{
      cursor: pointer;
    }
  }
  .divider{
    margin-bottom: 16px;
  }
  .top{
    margin-top: 16px;
  }
  li{
    list-style-type: disc;
    list-style-position: inside;
    &::before {
      content: "";
      margin-left: -7px;
    }
  }
  .text-decoration{
    text-decoration: underline;
  }
}
.privacy-policy-links{
  list-style-position: inside;
  &__hash{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $colorBlue80;
    &:hover{
      cursor: pointer;
    }
  }
  li{
      .privacy-policy-links__hash{
        margin-left: 25px;
      }
    &:nth-of-type(n+10){
      .privacy-policy-links__hash{
        margin-left: 16px;
      }
    }
    &::marker{
      width: 24px;
      min-width: 24px;
    }
    }
}
.table-policy{
  margin: 16px 0;
  border: 1px solid $colorGray40;
  tr{
    th{
      background-color: $colorGray20;
    }
    td{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $colorGray90;
      vertical-align: text-top;
    }
    th, td{
      border-top: 1px solid $colorGray40;
      padding: 8px 12px;
    }
  }
}