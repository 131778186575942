.contract-category-details-general {
  display: flex;
  &__details {
    max-width: 750px;
  }
  &__edit {
    max-width: 364px;
    .ant-form-item.input-default {
      width: 170px;
    }
  }
}
.contract-category-details-general__details__grid {
    max-width: 362px;
    width: 362px;
    .cc-details-item-half{
      width: 169px;
    }
    .cc-details-item{
      width: 362px;
    }
    .led-by-field{
      .ant-avatar{
       width: 24px;
        height: 24px;
        .ant-avatar-string{
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
}
.contract-category-details-responsible {
  display: grid;
  grid-template-columns: minmax(300px, 364px);
  grid-row-gap: 24px;
  margin-left: 24px;
}

