@import "src/styles/constants";

.create-survey {
  display: flex;
  flex-direction: column;
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
}

.survey-results {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;

    .ant-select-selection-placeholder {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $colorGray90;
    }
  }

  &__select {
    max-width: 364px;
  }

  &__bar-container {
    border-top: 1px solid $colorGray40;
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    position: relative;
  }

  &__bar {
    width: 101%;
    
  }
  &__bottom-line {
    position: absolute;
    height: 1px;
    background-color: $colorGray40;
    // top: 92%;
    left: 0;
    width: 100%;
  }
}
