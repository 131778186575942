@import "src/styles/constants";

.info-line {
  row-gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;

  &__content {
    width: calc(100% - 24px - 13px);
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &.info {
    background-color: $colorYellow10-2;

    svg {
      path {
        fill: $colorYellow50;
      }
    }
  }
}