@import "styles/constants";

.calendar {
  &__meeting-details-popover {
    width: 364px !important;
    &.schedule-popover{
      .ant-popover-content{
        right: 16px;
        top: -20px;
      }
    }
    &__content__info {
      max-height: 50vh;
      overflow: scroll;
    }
    .ant-popover-inner-content {
      padding: 0 16px 16px 16px !important;
    }
    &__row {
      margin-bottom: 12px;
      .mb-8 {
        margin-bottom: 8px;
      }
      .font-size-14 {
        font-size: 14px !important;
      }
      &__info {
        width: 100%;
      }
    }
    &__content-header {
      .typography-body-2 {
        color: $colorGray70;
      }
      &__block {
        width: calc(100% - 28px);
      }
      &__close {
        cursor: pointer;
      }
    }
    &__content-footer {
      .ant-btn {
        gap: 4px;
      }
      .decline-event {
        svg path {
          fill:  $colorRed40 !important;
        }
      }
    }
  }
}