@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat-header{
  display: flex;
  align-items: center;
  padding-left: 24px;
  @media only screen and (max-width: $screen-sm) {
    padding-left: 16px;
  }
  .mr-12, .chat-list-feature-item__name{
    &:hover{
      cursor: pointer;
    }
  }
}