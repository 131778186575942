.contract-admin-details-form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &__grid-row {
    display: grid;
    grid-template-columns: repeat(2, 364px);
    grid-column-gap: 24px;
  }

  &__grid-column {
    display: grid;
    grid-template-rows: repeat(3, minmax(60px, auto));
    grid-row-gap: 24px;
  }
}
