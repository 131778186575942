@import "styles/constants";

.clinical-review-modal-container{
  .ant-modal-body{
    padding-top: 0 !important;
  }
  .ant-modal-content{
    padding-bottom: 0;
    min-height: 500px;
  }
  .ant-modal-header{
    padding-bottom: 0 !important;
  }
  &__assigned{
    &__row{
      margin-top: 0 !important;
      width: auto !important;
      &__role{
        color: $colorGray70;
      }
      &.vendors{
        width: 67% !important;
      }
    }

  }
  &__legend{
    justify-content: flex-end;
    gap: 16px;
    .legend__rectangle {
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }

    .legend__description {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: $colorGray70;
    }
    .legend__rectangle_color_accept {
      background-color: $colorGreen20;
    }

    .legend__rectangle_color_decline {
      background-color: $colorRed20;
    }
    .legend__rectangle_color_no-response {
      background-color: $colorGray30;
    }
  }
  &__chart{
    gap: 8px;
    .clinical-review-results-item{
      position: relative;

    }
    .clinical-review-results-item-per{
      position: absolute;
      top: 4px;
      left: 50%;
    }
  }
  &__columns{
    max-height: 283px;
    .clinical-review-modal-container__columns__item {
      width: calc((898px - 12px) / 3);
      padding: 0 24px;
      row-gap: 16px;
      height: auto;
      overflow: auto;

      &:nth-of-type(1) {
        padding-left: 0;
      }

      &:nth-of-type(2) {
        border-left: 1px solid $colorGray40;
        border-right: 1px solid $colorGray40;
      }

      .hs-details_icon-name_container{
      &:last-child {
        margin-bottom: 16px;
      }
    }
    }
    .clinical-review-modal-container__columns__item__title{
      color: $colorGray70;
    }
  }
}
