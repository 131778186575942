@import "src/styles/constants";
@import "src/styles/breakpoint";

.radio-group-rating-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  @media only screen and (max-width:$screen-sm-max) {
    flex-direction: column;
    align-items: flex-start;
  }

}

.radio-group-range {
  &.ant-radio-group {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 72px;

    @media only screen and (max-width:$screen-md) {
      padding: 0;
    }

    @media only screen and (max-width:$screen-sm-max) {
      flex-direction: column;
    }
  }
}

.radio-group-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 8px;

  @media only screen and (max-width:$screen-sm-max) {
    flex-direction: row-reverse;
    gap: 12px;
    align-items: center;
  }

  .ant-radio-wrapper {
    margin-right: 0;
    margin-top: 12px;

    @media only screen and (max-width:$screen-sm-max) {
      margin: 0;
    }

    .ant-radio-inner {
      width: 21px;
      height: 21px;
      background-color: transparent;
      border-width: 1.5px;
      border-color: $colorGray70;

      &::after {
        transform: scale(.7);
      }
    }
  }
}

.radio-group__radio--error {
  .ant-radio-inner {
    border-color: $colorRed40 !important
  }
}
