@import "src/styles/breakpoint";

.sign-in{
  &__forget-password{
    @media only screen and (max-width: $screen-sm-max) {
      .ant-form .row .ant-row{
        flex-direction: row !important;
      }
      .ant-modal-content{
        padding: 48px 16px 16px 16px !important;
      }
    }
  }
}