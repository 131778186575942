@import "src/styles/constants";

.notification-card {
  padding: 16px 24px 16px 32px;

  &__message {
    overflow: hidden;

    .typography {
      color: $colorGray90;

      &:hover {
        color: $colorGray70;
      }
    }
  }

  &__content {
    column-gap: 12px;
  }

  &__info {
    min-width: 82px;
    column-gap: 4px;
  }

  &__created-at {
    color: $colorGray70;
  }
}
