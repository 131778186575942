.table-text-cell {
  &.ant-input {
    padding: 0 !important;
    border: none;
  }

  &--with-error {
    &.ant-input {
      background-color: transparent;
    }
  }
}
