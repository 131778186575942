@import "src/styles/constants";

.automatic-timer-sub-title{
  color: $colorGray70;
}

.prs-link-row{
  color: $colorBlue70;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &:hover, &:focus{
    color: $colorBlue80;
    cursor: pointer;
  }
}

.prs-table-price-row{
  .typography{
    text-align: right;
  }
}

.prs-list-status-content{
  .status-default{
    width: fit-content;
  }
}