@import "src/styles/constants";

.rfp-results {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &_contracts {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_contract {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .typography-caption {
      color: $colorGray70;
    }
  }
  &_vendor-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}