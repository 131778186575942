@import "styles/constants";

.hs-info {
  .container__content {
    background-color: $colorGray10;
  }

  &__general {
    padding: 24px 24px 0 24px;
  }

  &__header {
    column-gap: 8px;
  }

  &__general-content {
    margin-top: 16px;
    row-gap: 24px;

    .typography-label {
      margin-bottom: 4px;
    }
  }
  .national-gpo-wrapper{
    width: 243px;
    .gpo-field{
      .ant-avatar{
        width: 24px;
        height: 24px;
        .ant-avatar-string{
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .gpo-edit-icon{
      padding: 4px;
    }
  }
  .admin-split-fees-wrapper{
    .cognus_commons{
      margin-left: -10px;
    }
  }
  &__grid {
    display: grid;
    gap: 16px 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, minmax(44px, auto));
  }

  &__departments {
    grid-column: span 2;
  }

  &__show-btn {
    margin-top: 16px;
  }
}

.admin-split-fee-input {
    width: 200px;
}