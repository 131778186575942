.custom-edit-cells {
  display: flex;
  &.ant-input-number {
    background-color: transparent;
    input {
      padding: 0 !important;
      border: none;
      background-color: transparent;
    }
  }
  &.ant-select {
    width: 288px;
    .ant-select-selector {
      padding: 0 !important;
      border: none !important;
      background-color: transparent !important;
      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        left: 0;
      }
    }
  }
}