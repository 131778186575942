@import "src/styles/constants";

#edit-purchase-orders-form{
  .eligible-wrapper{
    gap: 8px;
  }
  .field-eligible{
    color: $colorGray60;
    margin-top: 6px;
  }
  .edit-purchase-orders__pack-wrapper{
    gap: 8px;
    .pack-title{
      padding-top: 28px;
    }
  }
  .edit-purchase-orders__date{
    .typography{

      margin-top: 16px;
      margin-bottom: 4px;
    }
    .two-columns{
      margin-top: 0;
    }
  }
}