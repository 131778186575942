@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat-input {
  display: grid;
  grid-template-columns: 1fr 40px;
  column-gap: 10px;
  min-height: 84px;
  padding: 12px 32px 32px 24px;
  @media only screen and (max-width: $screen-sm) {
    padding: 12px 16px 32px 16px;
  }
}
.chat-input__message-wrapper{
  position: relative;
}
.chat-input__message {
  textarea {
    height: 40px;
    min-height: 40px !important;
    overflow-y: hidden;
    max-height: 124px !important;
    padding: 8px 12px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $colorGray40;
    resize: none;
    font-size: 16px;
    line-height: 24px;
    @media only screen and (max-width: $screen-sm) {
      padding: 7px 12px;
    }
  }
  &__attachment{
    position: absolute;
    right: 12px;
    bottom: 8px;
    button{
      width: 24px;
      height: 24px;
      padding: 0 !important;
      svg{
        &:nth-of-type(1){
          display: none;
        }
        path{
          fill: $colorGray70 !important;
        }
      }
    }

  }
}
.chat-input__send {
  width: 40px;
  height: 40px;
  margin-top: auto;
  @media only screen and (max-width: $screen-md) {
    gap: 0 !important;
    margin-top: 0 !important;
  }
}
