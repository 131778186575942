@import "src/styles/constants";

.notifications-drawer {
  &.ant-drawer {
    position: absolute;
    z-index: 100;
  }

  &.ant-drawer-left {
    left: 236px
  }

  .ant-drawer-header {
    padding: 24px 24px 24px 32px;
  }

  .ant-drawer-body {
    padding: 0;
  }

  &__container {
    height: 100%;
  }

  &__title {
    padding-bottom: 24px;
  }

  &__switch {
    button {
      margin-right: 12px;
    }
  }

  &__counter {
    color: $colorGray70;
  }

  &__empty {
    height: 100%;
    row-gap: 6px;
  }

  &__empty-msg {
    color: $colorGray80;
  }

  &__date {
    padding: 8px 32px;
    background-color: $colorGray20;
  }
}
