.assign-responsible-popup .ant-modal-body {
  padding-top: 10px;
  padding-bottom: 0;
  overflow-y: visible !important;
  height: 200px;
}
.contract-assign-responsible {
  display: flex;
  flex-direction: column;
}
.contract-assign-responsible__select {
  margin-top: 12px;
}
.contract-assign-responsible__actions {
  display: flex;
  margin-top: 26px;
  float: right;

  & > button:not(:last-child) {
    margin-right: 16px;
  }
}
