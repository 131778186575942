@import "src/styles/constants";
@import "src/styles/breakpoint";

.chat-list-feature-item {
  position: relative;
  min-height: 90px;
  padding: 12px 20px 12px 32px;
  cursor: pointer;
  @media only screen and (max-width: $screen-md) {
    padding: 12px 20px 12px 16px;
  }

  &:hover {
    background-color: $colorGray10;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: $colorGray30;
    margin: 0 20px 0 32px;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.chat-list-feature-item__caption {
  color: $colorGray60;
  margin-top: 2px;
}
.chat-list-feature-item__chip {
  width: min-content;
  margin-top: auto;
  padding: 2px 8px;
  border: 1px solid $colorGray40;
  border-radius: 24px;
  background-color: $colorWhite;
}
.chat-list-feature-item__row{
  width: 100%;
  column-gap: 12px;

  &__wrapper {
    overflow: hidden;

    @media only screen and (max-width: $screen-md) {
      padding-top: 20px;
    }
  }
}
.chat-list-feature-item__row__description {
  gap: 4px;
  overflow: hidden;

  .column {
    overflow: hidden;
  }
}
.chat-list-feature-item__chip-text {
  color: $colorGray50;
}

.chat-list-feature-item--active {
  background-color: $colorBlue10;
}

.chat-list-feature-item--channel .ant-avatar{
  background-color: $colorBlue20;
  border: 1px solid $colorBlue60 !important;
  color: $colorBlue60;
}
.chat-list-feature-item__last-send {
  color: $colorGray70;
  text-transform: lowercase;
  @media only screen and (max-width: $screen-md) {
      position: absolute;
      right: 14px;
  }
}
.chat-list-feature-item__unread-messages {
  background: $colorYellow10-1;
  color: $colorYellow50;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  padding: 2px 6px;
  font-weight: 600;
  font-size: 12px;
  @media only screen and (max-width: $screen-md) {
    position: absolute;
    right: 14px;
    bottom: 12px;
  }
}