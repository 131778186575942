@import "src/styles/constants";

.input-textarea {
  position: relative;

  textarea {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px !important;
    color: $colorGray90;
    border: 1px solid $colorGray40;
    border-radius: 4px;
    background-color: $colorWhite;
    min-height: 124px !important;

    &:hover {
      border-color: $colorGray60;
    }

    &:focus {
      border-color: $colorBlue60;
    }

    &:active {
      border-color: $colorBlue70;
    }

    &:disabled {
      color: $colorGray90;
      background-color: $colorGray10;
      border-color: $colorGray40;
    }

    &::placeholder {
      color: $colorGray50;
    }
  }

  &.ant-form-item-has-success {
    .ant-form-item-explain-success {
      display: none;
    }
  }

  &.ant-form-item-has-error {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          border-color: $colorRed40;
        }
      }
    }
  }

  .ant-form-item-explain {
    min-height: auto !important;
  }

  .ant-form-item-explain-error {
    padding-top: 2px;
    color: $colorRed40;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}
