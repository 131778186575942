@import "src/styles/constants";
@import "src/styles/breakpoint";

.forms-sign-in{
  &__resend{
    margin-top: 32px;
    @media only screen and (max-width:$screen-sm-max) {
      margin-top: 24px;
    }
  }
}

.fa-form{
  &.error{
    .fa-code-input{
      border-color: $colorRed40;
    }
  }
  .fa-code-input {
    width: 34px;
    height: 40px;
    padding: 0;
    text-align: center;
    margin-right: 4px;
    text-transform: uppercase;
    color: $colorGray90;
    border: 1px solid $colorGray40;
    border-radius: 4px;
    background: $colorWhite;
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background-clip: padding-box;
    &:nth-of-type(3){
      margin-right: 16px;
    }
  }
  .fa-count-down{
    .ant-statistic-content{
        color: $colorGray90;
        font-family: $font-family;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
  }
  .button-default{
    padding: 0;
  }
  .fa-code-form-footer{
    gap: 12px;
  }
}