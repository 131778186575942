// transparent color for focus area
$primary-color: #ffffff00;

@import 'node_modules/@silevis/reactgrid/styles.scss';
@import "styles/constants";

.contract-bulk{
  height: 100%;
  &__table{
    margin-top: 4px;
    padding-bottom: 5px;
    overflow-x: auto;
    flex: 1;
    .rg-header-cell{
      padding: 8px 12px !important;
      border-left-color: $colorGray20 !important;
      border-right-color: $colorGray20 !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 16px !important;
      background-color: $colorGray20;
    }
    .rg-text-cell, .rg-number-cell, .rg-date-cell {
      padding: 0 !important;
      border: 0 !important;
    }
    .rg-dropdown-cell, .rg-number-cell, .ant-picker, .ant-select-selector, .rg-icon-cell, .input-wrapper {
      border-left-color: $colorWhite !important;
      border-top: 1px solid $colorBlue30 !important;
      border-bottom: 1px solid $colorBlue30 !important;
      border-right-color: $colorWhite !important;

      &.errored {
        border: 1px solid $colorRed40 !important;
      }
    }
    .rg-partial-area-selected-range {
      background: none !important;
    }
    .ant-select-selector{
      border-right-color: transparent !important;
      border-left-color: transparent !important;
      border-radius: 0 !important;
    }
    .input-default, .select-default{
      width: 100%;
      input{
        border: none;
      }
    }
  }
}