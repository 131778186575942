@import "src/styles/constants";
.support-information-container{
  padding: 24px;
  &__download{
    align-items: flex-end;
    &.withHistory{
      align-items: flex-start;
    }
    svg{
      width: 16px;
      height: 16px;
    }
    .attachment{
      padding: 8px 0 0;
    }
    button{
      padding: 0 12px !important;
      span{
        text-transform: uppercase;
        color: $colorBlue70;
      }
    }
  }
  .support-information-container__upload{
    button{
      max-height: 32px;
      background-color: transparent !important;
      gap: 4px;
    }
    &__history{
      &.withMargin{
        margin-top: 32px;
      }
      .show-version-history-button{
        span{
          text-transform: initial;
          padding-left: 24px;
          margin-top: 8px;
        }
      }
      .attachment__user{
        display: none;
      }
      .field-with-label{
        .typography-label{
          display: none;
        }
      }
      .attachment__created_at{
        margin-left: 34px;
      }
      .attachment{
        padding: 0;
        .attachment__content{
          .custom-button{
            padding-left: 0 !important;
          }
        }
      }
      .show-version-history-button{
        padding-left: 0 !important;
      }
      .attachments-list--with-separator::after {
        background: $colorBlue20;
      }
    }
  }
}