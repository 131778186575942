@import "styles/constants";

.calendar-schedule-view{
  padding-top: 120px;
}
.calendar-schedule-container{
  padding-top: 30px;
}

.day-view-container{
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $colorGray30;
  .day-view-container__wrapper{
    width: 176px;
    &.active{
      .day-view-container__day__number{
        border-radius: 50%;
        background-color: $colorBlue70;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        color: $colorWhite;
      }
      .day-view-container__day__weekday{
        color: $colorBlue70;
      }
    }
  }
  &__day{

    &__number{
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: $colorGray90;
      width: 40px;
      padding: 10px;
    }
    &__weekday{
      font-size: 11px;
      line-height: 12px;
      font-weight: 500;
      color: $colorGray70;
      margin-left: 8px;
    }
  }
  &__events{
    gap: 8px;
    width: calc(100% - 178px);
    .calendar-event__card {
      background-color: transparent;
      &.opened{
        border: 1px solid $colorBlue90;
        border-radius: 2px;
        background-color: $colorBlue20;
        opacity: 100% !important;
      }
      &:hover{
        background-color: $colorBlue10;
      }
      &.past{
        opacity: 60%;
      }
      .time-wrapper{
        width: 96px;
        margin-left: 16px;
        margin-right: 72px;
        margin-top: 4px;
        &__time{
          font-size: 15px;
          line-height: 22px;
        }
        &__duration{
          color: $colorGray50;
        }
      }
      .details-wrapper{
        margin-bottom: 4px;
        &__location{
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: calc(100dvw - 666px);
        }
      }
    }
  }
}