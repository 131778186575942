@import "src/styles/constants";

.ant-table-column-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .table-sorter-title {
    padding: 4px 0;
  }

  .table-sorter {
    height: 24px;
    padding: 4px;
    border-radius: 2px;

    &:hover {
      background-color: $colorGray30;
    }
    &:focus {
      border: 1px solid $colorBlue70;
      border-radius: 2px;
      background-color: $colorGray30;
    }
    svg {
      height: 16px;
      width: 16px;
      min-height: 16px;
      min-width: 16px;
    }
  }
}

.ant-table-column-sorter {
  display: none;
}