@import "src/styles/constants";

.assign-text{
  color: $colorGray70;
}
.reassign-popup{
  &__container{
    .ant-modal-body {
      overflow-y: visible !important;
    }
  }
  &__select-dropdown{
    .ant-select-item-option-content{
      gap: 5px;
    }
  }
  &__select{
    .assign-text{
      margin-left: 5px;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item{
      .assign-text{
        color: $colorGray50;
      }
    }
  }
}