@import "src/styles/breakpoint";

.rfp-list {
  .cell-with-subtitle {
    &__title {
      .typography {
        max-width: 180px;
      }
    }
  }
}

.rfp-list-dropdown-table{
  .dropdown-filter__option{
    .ant-form-item-control-input{
      .ant-checkbox-wrapper{
        @media only screen and (max-width: $screen-md) {
        span {
          &:nth-of-type(2) {
            max-width: calc(100vw - 60px);
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      }
    }
  }
}

.bidding-table-total-price-row{
  .typography{
    text-align: right;
  }
}