@import "styles/constants";
.contracts-table {
  &.contracts-page{
    table {
      td {
        vertical-align: top !important;
      }
      th{
        vertical-align: middle !important;
      }
      .contract-link{
          max-width: 235px;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
      }
    }
  }
  table {
    table-layout: fixed !important;
    .ant-table-cell {
      vertical-align: middle !important;
      .typography.typography-body-1 {
        max-width: 100% !important;
      }
    }
  }
  .ant-table {
    .ant-table-row {
      .separate-item {
        height: 48px;
      }
    }
  }
}
