@import "src/styles/constants";
@import "src/styles/breakpoint";

.container-portal {
  height: 100vh;
  overflow: auto;
  width: 100vw;
  @media only screen and (max-width: $screen-sm) {
    //height: $height-sm;
    height: 100dvh;
  }
  &_content-container {
    display: flex;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: $screen-md) {
      flex-direction: column;
      position: relative;
      min-height: calc(100dvh - 68px) !important;
    }
    @media only screen and (max-width: $screen-sm) {
      min-height: $height-sm;
      max-height: $height-sm;
    }
  }

  &__main {
    width: 100%;
    @media only screen and (max-width: $screen-md) {
      padding-top: 68px;
      height: 100%;
    }
    @media only screen and (max-width: $screen-sm) {
      padding-top: 56px;
      height: 100%;
    }
  }

  &__tabs {
    height: 100%;
    width: 236px;
    min-width: 236px;
    position: relative;
    z-index: 101;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 0 32px;
    background-color: $colorBlue70;
    color: $colorWhite;
    @media only screen and (max-width: $screen-md) {
      display: none;
    }
  }

  &__tabs_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;

    a:first-of-type {
      display: flex;
      padding: 0 16px 0 32px;
      width: 100%;
      align-items: center;
      min-width: 236px;
      position: relative;
      margin-bottom: 49px;
    }

    a:first-of-type::after {
      width: 86.5%;
      left: 32px;
      top: 52px;
      opacity: 1;
      position: absolute;
      height: 1px;
      content: "";
      background-color: $colorBlue40;
    }
  }

  &__tabs_bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;

    a:first-of-type {
      position: relative;
      margin-bottom: 17px;
    }

    a:first-of-type::after {
      width: 86.5%;
      left: 32px;
      top: 48px;
      opacity: 1;
      position: absolute;
      height: 1px;
      content: "";
      background-color: $colorBlue40;
    }
  }
}
