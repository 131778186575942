@import "src/styles/constants";

.password-strength {
  &__wrapper {
    height: 6px;
    border: 1px solid $colorGray50;
    border-radius: 4px;
  }

  &__indicator {
    height: 100%;
    width: 1%;
    transition: .3s width ease;

    &--weak {
      width: 15%;
      background: linear-gradient(90.37deg, #FF2929 1.56%, #F7B208 276.12%, #F7B208 505.34%, #23C751 706.47%);
    }

    &--medium {
      width: 55%;
      background: linear-gradient(90deg, #FF2929 0.93%, #F7B208 73.72%, #F7B208 134.48%, #23C751 187.81%);
    }

    &--strong {
      width: 100%;
      background: linear-gradient(90deg, #FF2929 0.93%, #F7B208 39.52%, #F7B208 71.73%, #23C751 100%);
    }
  }

  .typography {
    margin-top: 2px;
    color: $colorGray60;
  }
}