
@import "src/styles/constants";
@import "src/styles/breakpoint";

.forms-contact-us-landing{
  &__title{
    margin-bottom: 24px;
    @media only screen and (max-width: $screen-sm-max) {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .ant-form-item-label > label .ant-form-item-optional{
    color: $colorGray60;
    &::first-letter {
      text-transform:capitalize;
    }
  }
  .ant-form-item-label > label.ant-form-item-required:after {
    content: unset !important;
  }
  .ant-input-number-affix-wrapper{
    &.empty{
      .ant-input-number-prefix{
        color: $colorGray50;
      }
    }
  }
  &__btn{
    display: flex;
    width: 100%;
  }
  &__contact-us{
    padding: 10px 56px !important;
  }
}