.contract-details__grid-preview {
  display: grid;
  grid-template-rows: repeat(3, 44px);
  grid-row-gap: 24px;
  .led-by-field{
    .ant-avatar{
      width: 24px;
      height: 24px;
      .ant-avatar-string{
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}
.contract-details__grid-creation {
  display: grid;
  grid-template-rows: repeat(3, 60px);
  grid-row-gap: 24px;
}
.contract-details__grid-creation .custom-select.ant-form-item {
  max-width: 360px;
}
