.health-systems {
  position: relative;
  margin-top: 24px;

  &--empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    max-width: 333px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .typography {
      margin: 24px 0 16px 0;
    }
  }

  &__create-btn {
    margin-bottom: 24px;
  }
}
