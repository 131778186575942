@import "src/styles/constants";
@import "src/styles/mixins";
@import "src/styles/breakpoint";

#disable-send-btn{
  top: 16px;
  right: 0;
}

.send-prs-to-clinical-sponsor-disabled{
  display: inline-block;
  &:hover{
    cursor: not-allowed;
  }
  button{
    pointer-events: none;
  }
}

.send-prs-to-clinical-sponsor-btn{
  margin-left: 8px;
}

.approval-options-prs{
  margin-top: 12px;
  .checkbox-default .ant-checkbox + span{
    padding-left: 9px;
    padding-right: 0;
  }
  .ant-checkbox-group-item{
    margin-right: 0;
  }
  .ant-checkbox-group{
    row-gap: 12px;
    display: flex;
    flex-direction: column;
  }
}

.run-new-step-modal{
  .ant-modal-body{
    overflow-y: visible !important;
    .ant-space-item{
      .select-default{
        width: 338px;
      }
    }
    .renew-icon-wrapper{
      margin-left: 8px;
      padding: 8px;
      margin-top: 20px;
      &:hover{
        cursor: pointer;
        background-color: $colorBlue10;
      }
    }
    .run-new-step-option{
      &.disable{
        color: $colorGray50;
      }
      &__message{
        margin-top: -18px;
        color: $colorGray70;
        padding-left: 30px;
      }
    }

  }
}
#run-new-step-tooltip{
  top: 14px;
  right: 0;
}
#complete-voting-btn{
  max-width: 400px;
  top: 0;
  right: -2px;
}

.complete-voting-disabled{
  padding-top: 0 !important;
  max-width: 400px !important;
}
#disable-send-prs-btn{
  top: 0;
  right: 0;
  max-width: 346px;
}
#disable-request-trial-btn{
  max-width: 360px;
  top: 14px;
}

.disable-send-prs-btn-container{
  max-width: 346px !important;
  padding-top: 0 !important;
}
.disable-request-trial-btn-container{
  max-width: 360px !important;
}
.message-for-vendor-field{
  #requested-trial-form{
    width: 378px !important;
    max-width: 378px;
  }
}

.send-to-vendor-wrapper{
  margin-left: 8px;
}

.start-revenue-assessment-modal{
  .ant-modal-body{
    overflow-y: visible !important;
    .revenue-wrapper-select{
      .select-default{
        width: 338px;
      }
    }
    .renew-icon-wrapper{
      margin-left: 8px;
      padding: 8px;
      margin-top: 20px;
      &:hover{
        cursor: pointer;
        background-color: $colorBlue10;
      }
    }
  }

}

.approve-prs-from-stakeholder{
  @include mobileModal;
  .ant-modal-content{
    max-height: 100dvh;
    height: 100%;
    border-radius: 0;
    .ant-modal-body{
      overflow-y: auto !important;
      min-height: auto;
      max-height: calc(100dvh - 180px);
    }
  }
  .ant-modal-footer{
    max-width: 100vw;
    margin-left: 0;
  }
}