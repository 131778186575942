@import "src/styles/constants";

.chat-details-container {
  padding-top: 8px;

  &__chat-name {
    margin-top: 16px;

    &.link {
      color: $colorBlue80;

      &:hover {
        cursor: pointer;
      }
    }

    &.hideName {
      display: none;
    }
  }

  &__role,
  &__organization-name {
    color:$colorGray70;
    margin-top: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $colorGray90
  }
}