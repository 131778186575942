//colors
$colorBlue100: #184362;
$colorBlue90: #285F86;
$colorBlue80: #3374A3;
$colorBlue70: #3B85BA;
$colorBlue60: #5497C9;
$colorBlue50: #73A8D3;
$colorBlue40: #92BBDD;
$colorBlue30: #B5D1E8;
$colorBlue20: #D8E7F3;
$colorBlue10: #F3F8FB;
$colorBlue5Alpha: #0F71AE0D;
$colorBlue5: #D4E8F8;

$colorGray100: #1F2429;
$colorGray90: #323C43;
$colorGray80: #4D5B66;
$colorGray70: #6E7F8C;
$colorGray60: #91A0AC;
$colorGray50: #B3BEC6;
$colorGray40: #D0D7DC;
$colorGray30: #E8EBED;
$colorGray20: #F3F5F6;
$colorGray10: #F9FAFB;

$colorRed50: #A71111;
$colorRed40: #DF1616;
$colorRed30: #FF2929;
$colorRed20: #FF6666;
$colorRed10: #FF9999;
$colorRed8: #FFC7C7;
$colorRed4: #FFEBEB;

$colorGreen50: #147B31;
$colorGreen40: #1A9E3F;
$colorGreen30: #23C751;
$colorGreen20: #62DA84;
$colorGreen10: #A4EAB8;
$colorGreen5: #EEFBF2;
$colorGreen05: #DEF7E5;

$colorYellow50: #B48104;
$colorYellow40: #DC9D04;
$colorYellow30: #F7B208;
$colorYellow20: #FACA57;
$colorYellow20-1: #FAD16B;
$colorYellow10: #FCDD92;
$colorYellow10-1: #FCE3A6;
$colorYellow10-2: #FEF3D7;

$colorWhite: #FFF;
$colorBlack: #000;

//shadows

$boxShadow_1: 0 4px 6px 2px rgba(77, 91, 102, 0.12);
$boxShadow_2:  4px 4px 28px 6px rgba(51, 116, 163, 0.12);

$height-sm: calc(100dvh - 56px) !important;
$height-md: calc(100dvh - 68px) !important;
$height-sm-without-header: 100dvh !important;

$font-family: 'Open Sans';

