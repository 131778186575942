.items-list_template {
  margin-bottom: 10px;
  &.current-item {
    .attachment__content {
      .ant-btn.ant-btn-link.button-default {
        span {
          max-width: 250px !important;
          text-transform: uppercase;
        }
        max-width: 250px;
      }
    }
  }
}
.item-list-tabs{
  display: flex !important;
  .radio-group-timePeriod{
    display: flex;
  }
}
.replaced-info-button{
  width: 24px !important;
  height: 24px !important;
  padding: 4px !important;
}