@import "src/styles/constants";

.prs-details-reason{
  row-gap: 13px;
  padding: 10px 16px;
  border-radius: 2px;
  width: 100%;
  &__content{
    width: calc(100% - 24px - 13px);
  }
  svg{
    width: 16px;
    height: 16px;
  }
  &.decline, &.declined, &.rejected{
    background-color: $colorRed4;
    svg{
      path{
        fill:$colorRed50;
      }
    }
  }
  &.info{
    background-color: $colorYellow10-2;
    svg{
      path{
        fill:$colorYellow50;
      }
    }
  }

  &.approved{
    background-color: $colorGreen05;
    svg{
      path{
        fill:$colorGreen50;
      }
    }
  }

}