@import '../../../../../styles/constants';

.contract-version-history {
  display: flex;
  flex-direction: column;
}
.contract-version-history__document {
  position: relative;
  display: flex;
  flex-direction: row;

  .attachment {
    width: 100%;
  }

  &:last-child {

    .contract-version-history__node::after {
      display: none;
    }
  }
}
.contract-version-history__node {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 19px;
  transform: translateY(15px);

  &::before {
    content: '';
    display: block;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    background-color: $colorBlue30;
  }

  &::after {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background-color: $colorBlue30;
  }
}
