@import "src/styles/constants";
@import "src/styles/breakpoint";

.radio-group-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  .ant-radio-group{
    border-radius: 0 4px 4px 0;
    .ant-radio-button-wrapper{
      &:nth-of-type(1){
        border-right-color: $colorGray10 !important;
      }
      &:nth-of-type(2){
       &::before{
         background-color: $colorGray30;
       }
      }
    }
    &:nth-of-type(0){
      border-radius: 4px 0 0 4px;
    }
    .ant-radio-button-wrapper{
      background-color: $colorGray10;
      border: 1px solid $colorGray30;
      span{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $colorGray70;
      }
      .ant-radio-button{
        &::before{
          display: none;
        }
      }
      &.ant-radio-button-wrapper-checked{
        background-color: $colorBlue10;
        border: 1px solid $colorGray30;
        span{
          font-weight: 600;
          color: $colorBlue80;
        }
      }
    }
  }

}
