.page-not-found__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.page-not-found__content {
  margin-top: 35px;
  text-align: center;
}
.page-not-found__error-message {
  margin-top: 17px;
}
.page-not-found__container--fixed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
