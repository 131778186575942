.container-with-history {
  display: flex;
  flex-direction: column;
}
.show-version-history-button {
  margin-top: 8px;
  &.ant-btn {
    font-weight: 400;
  }
}
