@import "src/styles/constants";

.trial-pre-approval-container{
  .trial-pre-approval-chart-wrapper{
    padding: 24px;
    justify-content: space-between;
    border: 1px solid $colorBlue20;
    border-radius: 8px;
    max-width: 1140px;
    align-items: center;
    row-gap: 80px;
    .title{
      color:$colorGray70;
    }
    .amount{
      color:$colorBlue90;
    }
    &__right{
      max-width: 842px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      &__chart{
        width: 100%;
        gap: 2px;
        max-width: 842px;
      }
      &__agenda{
        gap: 48px;
      }
      .charts_ongoing-contracts_convention_color-count{
        display: flex;
        flex-direction: row;
        align-items: center;
        row-gap: 8px;
        .charts_ongoing-contracts_convention_name{
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          width: fit-content;
          max-width: fit-content;
        }
      }
    }
  }

}