@import "src/styles/constants";
@import "src/styles/breakpoint";

.contact-us-popup{
  @media only screen and (max-width: $screen-sm-max) {
    .ant-modal-body{
      max-height: 100vh !important;
    }
    .ant-form .row .ant-row{
      flex-direction: row !important;
    }
    .ant-modal-content{
      padding: 48px 16px 16px 16px !important;
    }
  }
}

.sign-popup {
  width: 100%;
  max-width: 442px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 8px;

  @media only screen and (max-width: $screen-sm-max) {
    .ant-form .ant-form-item{
      flex-wrap: nowrap !important;
    }
    .ant-form .ant-form-item .ant-form-item-label{
      flex: 0 0 35%;
    }
  }

  &__logo {
    width: 204px;
    height: 44px;
  }

  &-wrap {
    background: linear-gradient(142.15deg, #92BBDD 9.68%, #B5D1E8 77.42%);
    @media only screen and (max-width:$screen-sm-max) {
      display: flex;
      overflow-x: hidden !important;
      background: white;
      .ant-modal-content{
        padding: 40px 16px !important;
      }
      .ant-modal-body{
        max-height: 100vh !important;
      }
    }
  }

  .password-strength {
    margin-top: 4px;
  }

  footer {
    margin-top: 32px;
    @media only screen and (max-width:$screen-sm-max) {
      margin-top: 24px;
    }

    .typography {
      text-align: left;
    }

    .button-default.ant-btn {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    .contact-us-footer{
      max-height: 20px;
    }
    .contact-us-wrapper{
      margin-bottom: 8px;
    }
  }
  .sign-popup-wrap-back{
    padding: 12px;
    border-radius: 4px;
    background: $colorWhite;
    width: 40px;
    height: 40px;
    left: -64px;
    position: absolute;
    top: 0;
    &:hover{
      cursor: pointer;
    }
  }
}
.terms-links{
  &__separator{
    width: 1px;
    background-color: $colorGray70;
    margin: 0 12px;
    height: 20px;
  }
}
