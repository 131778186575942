@import "src/styles/constants";

.response-container{
  &__item{
    padding: 32px 24px;
    background-color: $colorBlue10;
    border: 1px solid $colorBlue20;
    border-radius: 7px;
    &.error{
      border: 1px solid $colorRed40;
    }
    &__question{
      display: flex;
      &.isRequired{
          &::after {
            content: '*';
            display: block;
            margin: 0;
            color: $colorRed40;
        }
      }
    }
    .input-textarea{
      width: 100%;
    }
  }
  .multi-questions-preview-container{
    .ant-checkbox-group{
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top:16px;
    }
    &__checkbox-with-input{
      .ant-checkbox-wrapper{
        align-items: center !important;
      }
    }
  }
  textarea{
    resize: none;
  }
  &.preview{
    .ant-radio-inner{
      &::after{
        display: none;
      }
    }
  }

}