@import "src/styles/constants";

.vendor-info-container {
  &--no-padding {
    padding: 0;
  }

  .container__content {
    margin-top: 16px;
  }
}

.vendor-info {
  padding-top: 24px;
  padding-left: 2px;
  background-color: $colorGray10;

  &__general {
    padding: 0 24px;

    .typography-label {
      margin-bottom: 4px;
    }
  }

  &__general-content {
    margin-top: 16px;
  }

  &__grid {
    display: grid;
    gap: 16px 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, auto);
  }

  &__show-btn {
    margin-top: 16px;
  }
}
