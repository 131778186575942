@import "src/styles/constants";

.custom-menu {
  &.ant-menu-horizontal {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    line-height: 24px;
    border: none;

    .ant-menu-item {
      padding: 12px !important;
      color: $colorGray70;

      &::after {
        left: 0;
        width: 100%;
        border-color: $colorGray50;
        border-width: 1px;
        border-radius: 1px 1px 0 0;
      }

      &:hover{
        color: $colorGray80 !important;
        background: $colorBlue10;
        &::after {
          border: 1px solid $colorGray50 !important;
        }
      }

    &.ant-menu-item-active{
      color: $colorGray70;
      &::after{
        color: $colorGray70;
        border-width: 1px;
        border-radius: 0px 0px 0 0 !important;
        border-color: $colorGray50;
      }
    }
      &.ant-menu-item-selected{
        color: $colorGray90;
        &::after {
          border-color: $colorBlue70;
          border-width: 4px;
          border-radius: 2px 2px 0 0;
        }
      }

      &.ant-menu-item-danger {
        display: none;
      }

      &.ant-menu-item-disabled,
      &.ant-menu-item-disabled:hover {
        color: $colorGray50 !important;

        &::after {
          border-bottom: 1px solid $colorGray40 !important;
        }
      }
    }
  }
}