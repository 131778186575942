@import "src/styles/breakpoint";
@import "src/styles/mixins";
@import "src/styles/constants";

.prs-details{
  @media only screen and (max-width: $screen-md) {
    //overflow-y: hidden;
  }
  .prs-details-participants, .prs-details-general-container{
    @media only screen and (max-width: $screen-md) {
      padding-bottom: 40px;
    }
  }
}
.prs-create-survey-popup {
  @include mobileModal;
  .ant-modal-body {
    overflow-y: auto !important;
  }
  @media only screen and (max-width: $screen-sm-max) {
    .ant-modal-body.ant-modal-body {
      min-height: calc(100dvh - 200px);
    }
    .ant-modal-content {
      height: 100dvh;
      border-radius: 0;
    }
    .ant-modal-body {
      overflow-y: auto !important;
    }
  }
  @media only screen and (max-width: $screen-md) {
    .ant-modal-body {
      overflow-y: auto !important;
    }
  }
}

.prs-details {
  .container__status{
    .two-status{
      display: flex;
      gap: 12px;
    }
  }
}

#prs-timeline-form-id{
  .timeline{
    &:nth-of-type(1){
      grid-template-columns: 1fr auto auto minmax(80px, auto) !important;
    }
    grid-template-columns: 1fr auto auto !important;
  }
}
