@import "styles/constants";

.led-by-indicator{
  border-radius: 50%;
  .ant-avatar{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    .ant-avatar-string{
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  &.cognus_commons{
    .ant-avatar {
      background: $colorBlue20;
      border: 1px solid $colorBlue90;
      color: $colorBlue90;
    }
  }
  &.community{
    .ant-avatar {
      background: $colorGray70;
      border: 1px solid $colorGray70;
      color: $colorWhite;
    }
  }
  &.health_system{
    .ant-avatar {
      background: $colorYellow10;
      border: 1px solid $colorYellow50;
      color: $colorYellow50;
    }
  }
  &.cognus_for_community{
    .ant-avatar {
      background: $colorGreen10;
      border: 1px solid $colorGreen50;
      color: $colorGreen50;
    }
  }
  &.cognus_for_health_system{
    .ant-avatar{
      background: $colorGray40;
      border: 1px solid $colorGray90;
      color: $colorGray100;
    }
  }
  &.national_gpo{
    .ant-avatar{
      background: $colorGreen50;
      border: 1px solid $colorGreen50;
      color: $colorWhite;
    }
  }
}

#led-by-indicator-tooltip{
  min-width: 100px;
  width: fit-content;
}