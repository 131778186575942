.communities-list {
  &__search {
    max-width: 364px;

    &.ant-row {
      margin-bottom: 16px !important;
    }
  }

  &__show-btn {
    margin-bottom: 16px;
  }
}
