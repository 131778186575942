.rebate-conditions-form {
  max-width: 378px;

  &.ant-form {
    .row {
      margin: 0;
    }
  }

  &__actions {
    &.row {
      .custom-button.ant-btn-primary {
        width: auto;
      }
    }
  }
}
