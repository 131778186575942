@import "src/styles/constants";

.rfp-create-survey-preview {
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
  padding: 32px 24px;
}
.survey-preview__ratings {
  margin-top: 32px;
}
.survey-vendor-rating {
  border-bottom: 1px solid $colorBlue40;
  padding-bottom: 16px;
  margin-bottom: 16px;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0;
  }
}
.survey-category-wrapper {
  border: 1px solid $colorBlue20;
  background: $colorWhite;
  border-radius: 4px;

  &.label-color {
    color: $colorGray70;
  }
}

