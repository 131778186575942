@import "src/styles/constants";

.health-systems-list {
  background-color: $colorWhite;

  &__search {
    max-width: 364px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    padding: 12px 24px;
    background-color: $colorWhite;
    border-bottom: 1px solid $colorBlue20;
    cursor: pointer;

    &:hover {
      background-color: $colorGray10;
    }
  }

  &__empty {
    padding: 24px 12px;
    border-bottom: 1px solid $colorBlue30;
  }
}
