@import "src/styles/constants";

.common-response-wrapper{
  row-gap: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid $colorBlue20;
  max-width: 752px;
  &__more{
    color: $colorBlue70;
    &:hover{
      cursor: pointer;
    }
  }
  &__answers-wrapper{
    row-gap: 12px;
  }
  &.isRequired{
    .common-response-wrapper__question{
      display: flex;
      &::after {
        content: '*';
        display: block;
        margin: 0;
        color: $colorRed40;
      }
    }
  }
  &__statistics-row{
    max-width: 752px;
    width: 100%;
    row-gap: 7px;
    &__header{
      &__percent{
        span{
          color: $colorGray70;
        }
      }
    }
    &__line{
      position: relative;
      height: 16px;
      width: 100%;
      max-width: inherit;
      background-color: $colorBlue20;
      border-radius: 4px;
      &__colored{
        position: absolute;
        left: 0;
        top: 0;
        height: 16px;
        background-color: $colorBlue60;
        border-radius: 4px;
      }
    }
  }
  &__other-response{
    &__count{
      color: $colorGray70;
    }
  }
}

.individual-response-wrapper{
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
  padding: 32px 24px;
  max-width: 752px;
  row-gap: 16px;
  &.isRequired{
    .individual-response-wrapper__question{
      display: flex;
      &::after {
        content: '*';
        display: block;
        margin: 0;
        color: $colorRed40;
      }
    }
  }
  &__single{
    .ant-space{
      gap: 16px !important;
      .ant-radio-disabled + span {
        color: $colorGray90;
      }
      label{
        align-items: center;
        height: 40px;
      }
      .ant-radio-checked{
        .ant-radio-inner{
          border-color: $colorBlue70 !important;
          &::after{
            background-color: $colorBlue70 !important;
          }
        }
      }
      .ant-radio-inner {
        width: 21px;
        height: 21px;
        background-color: transparent;
        border-width: 1.5px;
        border-color: $colorGray70;

        &::after {
          transform: scale(.7);
        }
      }
    }
  }
  &__multi{
    gap: 16px !important;
    .ant-checkbox-checked{
      .ant-checkbox-inner{
        &::after{
          border-color: $colorWhite !important;
        }
      }
    }
    label{
      height: 40px;
    }
  }
}