@import "src/styles/constants";

.prs-history{
  margin-left: 16px;
  display: flex;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
}

.prs-history-item{
  padding: 12px 0;
  &__acton-title{
    align-items: center;
    .ant-avatar-group{
      margin-right: 8px;
    }
  }
  &__status-wrapper{
    padding-left: 40px;
    gap: 12px;
  }
  &__comment{
    padding-left: 40px;
    &__label{
      color: $colorGray70;
    }
  }
  &__date-wrapper{
    padding-left: 40px;
    gap: 16px
  }
}