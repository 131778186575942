@import "src/styles/constants";

.rejected-invoices-table {
  .ant-table {
    tr {
      th.ant-table-cell {
        vertical-align: middle;
      }

      td.ant-table-cell {
        vertical-align: middle;

        &.cell-with-error {
          background-color: $colorRed4 !important;
        }
      }
    }
  }
}
