@import "src/styles/constants";

.vendor-item {
  &__content {
    border: 1px solid $colorBlue20;
    border-radius: 4px;
    background-color: $colorWhite;

    svg {
      max-width: 16px;
      width: 100%;
      cursor: pointer;
    }
  }
}
