@import "src/styles/constants";
@import "src/styles/breakpoint";
@import "src/styles/mixins";

.landing-page{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  @media only screen and (max-width: $screen-sm) {
    height: 100dvh;
  }
  &__header{
    position: fixed;
    z-index: 3;
    padding: 20px 64px;
    background-color: $colorBlue5;
    @media only screen and (max-width: $screen-lg) {
      padding: 20px 32px 20px 64px;
    }
    @media only screen and (max-width: $screen-sm) {
      padding: 16px;
    }
    &__logo{
      width: 164px;
      height: 32px;
      @media only screen and (max-width: $screen-sm) {
        width: 124px;
        height: 24px;
      }
    }
    &__menu{
      display: none;
      @media only screen and (max-width: 743px) {
        display: initial;
      }
    }
    &__links{
      color: $colorBlue90;
      .typography{
        padding: 0 32px;
        @media only screen and (max-width: $screen-lg) {
          padding: 0 22px;
        }
        @media only screen and (max-width: 743px) {
          display: none;
        }
        &:hover{
          cursor: pointer;
        }
      }
    }
    &__actions{
      @media only screen and (max-width: 743px) {
        display: none !important;
      }
    }
  }
  &__banner{
    position: relative;
    z-index: 0;
    padding: 160px 175px 146px;
    justify-content: center;
    align-items: center;
    width: calc(100vw + 132px);
    border-bottom-left-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    background: linear-gradient(180deg, #DCECF9 -2.57%, #84BDEB 162.04%);
    svg{
      width: 422px;
      height: 317px;
    }
    @media only screen and (max-width: $screen-lg) {
      width: calc(100vw + 107px);
      padding: 100px 94px 102px;
      svg{
        height: 250px;
        width: 364px;
      }
    }
    @media only screen and (max-width: $screen-sm) {
      width: calc(100vw + 107px);
      padding: 78px 73px 100px;
      display: flex;
      flex-direction: column !important;
      svg{
        height: auto;
        order: 1;
        width: calc(100vw - 108px);
        margin-right: 10px;
      }
    }
    &__action {
      @media only screen and (max-width: $screen-sm) {
        order: 2;
      }
      .landing-page__banner__action__contact-us{
        padding: 10px 56.5px;
        @media only screen and (max-width: $screen-sm) {
          width: 100%;
        }
      }
      &__title{
        max-width: 644px;
        @media only screen and (max-width: $screen-lg) {
          font-size: 32px;
          line-height: 44px;
          text-align: left;
        }
        @media only screen and (max-width: $screen-sm) {
          font-size: 20px;
          line-height: 28px;
          text-align: left;
        }
      }

    }
  }
  &__section-first{
    z-index: 1;
    position: relative;
    box-shadow: $boxShadow_2;
    margin: -50px 64px 0;
    background-color: $colorWhite;
    border-radius: 12px;
    max-width: 1090px;
    padding: 64px 40px;
    text-align: center;
    &__subtitle{
      margin-top: 40px;
      font-size: 16px;
      line-height: 24px;
    }
    &__title{
      font-size: 32px;
      line-height: 44px;
      font-weight: 500;
    }
    @media only screen and (max-width: $screen-lg) {
      max-width: 714px;
      padding: 44px 40px;
      &__title{
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
      }
      &__subtitle{
        margin-top: 24px !important;
        font-size: 16px;
        line-height: 24px;
      }
    }
    @media only screen and (max-width: $screen-sm) {
      margin: -50px 16px 0;
      padding: 32px 24px;
      &__title{
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }
      &__subtitle{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__who-we-are{
    max-width: 1370px;
    position: relative;
    padding: 120px 64px 0;
    @media only screen and (max-width: $screen-lg) {
      padding: 120px 32px 0;
    }
    @media only screen and (max-width: $screen-sm-max) {
      padding: 93px 16px 0;
    }
    &__title{
      text-align: center;
      @media only screen and (max-width: $screen-lg) {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700 !important;
      }
      @media only screen and (max-width: $screen-sm-max) {
        font-weight: 600 !important;
      }
    }
    &__content{
      position: relative;
      width: 100%;
      margin-top: 40px;
      @media only screen and (max-width: $screen-sm-max) {
        display: flex;
        flex-direction: column !important;
        margin-top: 17px;
      }
      &__health-system, &__vendors{
        width: calc(100% / 2 - 12px);
        border-radius: 16px;
        padding: 64px 32px;
        position: relative;
        z-index:1;
        height: fit-content;
        @media only screen and (max-width: $screen-lg) {
          padding: 44px 32px;
        }
        @media only screen and (max-width: $screen-sm-max) {
          width: 100%;
        }
      }
      &__health-system{
        background-color: $colorBlue20;
      }
      &__vendors{
        background-color: $colorBlue70;
        margin-top: 120px;
        .typography{
          color: $colorWhite;
        }
        @media only screen and (max-width: $screen-sm-max) {
          margin-top: 16px;
        }
      }
      &__circle1 {
        @include circleFonts;
        bottom: 22px;
        width: 172px;
        left: 128px;
        height: 172px;
        background-color: $colorBlue80;
        @media only screen and (max-width: $screen-lg) {
          left: 0;
          bottom: -8px;
        }
        @media only screen and (max-width: $screen-sm-max) {
          bottom: -50px;
          width: 76px;
          height: 76px;
        }
      }
        &__circle2{
          @include circleFonts;
          bottom: 0;
          width:56px;
          height: 56px;
          left: 474px;
          opacity: 0.5;
          background-color:$colorBlue80;
          @media only screen and (max-width: $screen-lg) {
            left: 312px;
            bottom: -49px;
          }
          @media only screen and (max-width: $screen-sm-max) {
            display: none;
          }
        }
        &__circle3{
          @include circleFonts;
          top: 25px;
          width:64px;
          height: 64px;
          right: 469px;
          background-color:$colorBlue20;
          @media only screen and (max-width: $screen-lg) {
            top: 42px;
            right: 368px;
          }
          @media only screen and (max-width: $screen-sm-max) {
            display: none;
          }

        }
        &__circle4{
          @include circleFonts;
          top: -4px;
          width:40px;
          height: 40px;
          opacity: 0.3;
          right: 315px;
          background-color:$colorBlue20;
          @media only screen and (max-width: $screen-lg) {
            top: -20px;
            right: 212px;
          }
          @media only screen and (max-width: $screen-sm-max) {
            width: 27px;
            height: 27px;
            top: -53px;
            right: 60%;
            left: 40%;
          }
        }
        &__circle5{
          @include circleFonts;
          top: 0;
          width:169px;
          height: 169px;
          opacity: 0.6;
          right: 68px;
          background-color:$colorBlue20;
          @media only screen and (max-width: $screen-lg) {
            top: 14px;
            right: -11px;
          }
          @media only screen and (max-width: $screen-sm-max) {
            width: 86px;
            height: 86px;
            top: -30px;
            right: -25px;
          }
        }


    }
  }
  &__contact-us{
    padding-top: 120px;
    @media only screen and (max-width: $screen-sm-max) {
      padding-top: 80px;
    }
    @media only screen and (max-width: $screen-sm-max) {
      padding: 80px 16px 0;
    }
    &__title{
      text-align: center;
      @media only screen and (max-width: $screen-lg) {
        font-size: 24px;
        font-weight: 700 !important;
        line-height: 32px;
      }
      @media only screen and (max-width: $screen-sm-max) {
        font-weight: 600 !important;
      }
    }
    &__subtitle{
      margin-top: 20px;
      text-align: center;
      @media only screen and (max-width: $screen-lg) {
        margin-top: 16px;
        max-width: 580px;
      }
    }
    .forms-contact-us-landing__form{
     gap: 24px;
      max-width: 866px;
      @media only screen and (max-width: $screen-lg) {
        max-width: 716px;
      }
      @media only screen and (max-width: $screen-sm-max) {
        .ant-row{
          width: 100% !important;
        }
        .row{
          display: flex;
          flex-direction: column;
          gap: 24px
        }
      }
      .row{
        margin-top: 0 !important;
      }
      textarea{
        resize: none;
      }
    }
    .contact-us-landing-btn{
      button{
        width: initial;
        padding: 10px 56.5px !important;
        @media only screen and (max-width: $screen-sm-max) {
          width: 200px !important;
        }
      }
    }
  }
  &__footer{
    background-color: $colorBlue80;
    padding: 64px 175px;
    margin-top: 120px;
    @media only screen and (max-width: $screen-lg) {
      padding: 64px 155px;
    }
    @media only screen and (max-width: $screen-lg-min) {
      padding: 64px 32px;
    }
    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 80px;
      padding: 44px 16px;
      display: flex;
      flex-direction: column !important;
      gap: 44px;
    }
    &__logo{
      width: 164px;
      height: 32px;
    }
    &__links{
      color: $colorWhite;
      row-gap: 12px;
      &:hover{
        cursor: pointer;
      }
    }
    &__info{
      color: $colorWhite;
      row-gap: 16px;
      &__mail{
        a{
          color: $colorWhite;
          &:hover, &:active{
            color: $colorWhite;
          }
        }

      }
      svg{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
}
.mobile-menu-drawer{
  .ant-drawer-mask{
    display: none !important;
  }
  .ant-drawer-content-wrapper{
    width: 100vw !important;
    height: 328px !important;
    border-radius: 0;
    .ant-drawer-header, .ant-drawer-content{
      background-color: $colorBlue5;
    }
    .ant-drawer-header{
      padding: 16px;
      .ant-drawer-header-title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .ant-drawer-title{
          order: 1;
          line-height: 0;
        }
        button{
          order: 2;
          margin-right: 12px;
        }
      }
    }
    .ant-drawer-body{
      padding: 24px 16px;
    }
    .landing-page__header__actions{
      display: initial !important;
      margin-top: 64px;
      button{
        margin-top: 64px;
        width: 100%;
      }
    }
    .landing-page__header__links{
      display: flex;
      flex-direction: column !important;
      text-align: center;
      gap: 24px;
      .typography{
        display: initial !important;
      }
    }
  }
}