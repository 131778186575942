.contract-categories {
  max-width: 461px;

  &__categories-popup {
    .ant-modal-body {
      overflow: unset;
    }
  }

  &__add-btn {
    max-width: 228px;
  }

  &__actions {
    &--hidden {
      display: none !important;
    }
  }
}
