@import "styles/constants";

.cpr-delete-hs-button{
  svg{
    width: 20px;
    height: 20px;
    path{
      fill: $colorRed40 !important;
    }
  }
  &.disabled{
    path{
      fill: $colorGray60 !important;
    }
  }
}