@import "src/styles/constants";

.custom-datepicker {
  .ant-picker {
    border-radius: 4px;
    border: 1px solid $colorGray40;
  }

  &.ant-form-item {
    .ant-form-item-label {
      line-height: 16px;
      text-align: left;
      padding-bottom: 4px;

      label {
        height: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorGray90;

        &.ant-form-item-required {
          &::before {
            display: none;
          }

          &::after {
            content: '*';
            display: block;
            margin: 0;
            color: $colorRed40;
          }
        }
      }
    }

    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      padding: 0;
      border-radius: 4px;
      border-color: $colorGray40;
      box-shadow: none;

      &:hover {
        border-color: $colorGray60;
      }

      &:focus, &.ant-input-affix-wrapper-focused,&.ant-input-affix-wrapper-focused:hover{
        border-color: $colorBlue60;
        box-shadow: none;
      }

      &:active {
        border-color: $colorBlue70;
      }

      &:disabled {
        background-color: $colorGray10;
        border-color: $colorGray40;
        cursor: not-allowed;
      }

      &.ant-input-affix-wrapper-disabled {
        border-color: $colorGray40;
      }

      .ant-input-prefix {
        margin: 0 0 0 12px;
      }

      input {
        border: none;
      }
    }
  }
}
