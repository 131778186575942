@import "src/styles/constants";

.history-card {
  padding: 12px 0;

  .custom-link {
    font-weight: 600;
  }

  &__date {
    color: $colorGray70;
  }
}
