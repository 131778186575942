@import "src/styles/constants";

.calendar-header {
  background: $colorGray20;

  &__content {
    font-weight: 400;
    color: $colorGray90;
    text-transform: uppercase;
  }
}

.calendar-cell {
  border: 1px solid $colorBlue20 !important;

  .fc-daygrid-day-top {
    opacity: 1 !important;
  }

  .fc-daygrid-day-frame {
    .fc-daygrid-day-top {
      display: flex;
      flex-direction: row;
    }
  }

  &__num-label{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorGray70;
  }

  .fc-popover-header {
    padding-top: 16px;
    background: $colorWhite;
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 12px;
    color: $colorBlue70;
    font-weight: 600;
    text-transform: uppercase;

    .fc-icon-x {
      display: none;
    }
  }

  .fc-popover-body {
    padding-top: 0 !important;
    background: $colorWhite;

    .fc-more-popover-misc {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;

      .calendar-cell__num-label {
        width: 36px;
        height: 36px;

        .date-label {
          font-size: 18px;
        }
      }
    }
  }

  &__grayed {
    .fc-daygrid-day-frame {
      background: $colorGray10;
    }
  }

  &__active {
    .fc-daygrid-day-frame {
      background: $colorWhite;
    }
  }

  &__today {
    background: $colorBlue70;
    color: $colorWhite;
    border-radius: 50%;
  }
}

.calendar-event {
  border: none;

  &__card {
    border-radius: 2px;
    cursor: pointer;
    background: $colorBlue20;
  }

  &__badge {
    height: inherit;
    border: 1px solid $colorBlue70;
    border-radius: 2px 0 0 2px;
    width: 6px;
  }

  &__context {
    border: 1px solid $colorBlue20;
    border-left: none;
    border-radius: 2px;
    padding: 4px 0 4px 5px;
    overflow: hidden;

    .calendar-time-title {
      color: $colorGray100;
      padding-right: 4px;
    }

    .calendar-event-name {
      color: $colorGray100;
      overflow: hidden;
    }
  }

  &__status-active {
    .calendar-event__badge {
      background: $colorBlue70;
    }
  }

  &__status-pending {
    .calendar-event__badge {
      background: repeating-linear-gradient(135deg, $colorBlue20, $colorBlue20 2px, $colorBlue70 2px, $colorBlue70 4px);
    }

    .calendar-event__context, &.calendar-event__card.schedule-view__card {
      border: 1px dashed $colorBlue70;
      border-left: none;
    }
  }

  &__status-maybe {
    .calendar-event__badge {
      background: repeating-linear-gradient(135deg, $colorBlue20, $colorBlue20 2px, $colorBlue70 2px, $colorBlue70 4px);
    }
  }

  &::after {
    background: none !important;
  }
}

.calendar-cell-more-link {
  color: $colorGray70;
  margin: 8px 0 0 8px !important;
}