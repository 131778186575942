.calendar {
  &__modal {
    &__type-of-event-select {

    }
  }
}
.calendar-wrapper {
  &__content {
    flex: 1;
    overflow-y: auto;
  }
}