@import "src/styles/constants";

.attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  position: relative;

  .progressBar-container{
    position: absolute;
    bottom: 22px;
    left: 40px;
  }
  .error-message-container{
    .typography{
      color: $colorRed40;
      position: absolute;
      top: 46px;
      left: 40px;
      word-break: break-all;
      max-width: 210px;
    }
  }
  .progressBar-wrapper{
    border-radius: 4px;
    height: 6px;
    background-color: $colorBlue20;
    width: 200px;
    position: relative;
    .percentage-amount{
      position: absolute;
      border-radius: 4px;
      height: 6px;
      top: 0;
      left: 0;
      background-color: $colorBlue60;
    }
  }


  .custom-button {
    &.ant-btn-link {
      &.button-default {
        height: auto;
        max-width: 310px;
        width: 100%;
        margin-right: 12px;
        gap: 8px;
        color: $colorGray90;

        &:hover {
          color: $colorBlue80;
        }

        &:focus,
        &:active {
          color: $colorBlue90;
        }

        span {
          max-width: 210px;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
        }
      }

      &.button-icon {
        svg path {
          fill: $colorRed40;
        }
      }

      .attachment__icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.attachment__created_at {
  display: flex;
  flex-direction: row;
  margin-left: 22px;

  & > .typography {
    color: $colorGray70
  }

  & > div:last-child {
    margin-left: 16px
  }
}

.attachment-table {
  .custom-button {
    &.ant-btn-link {
      &.button-default {
        width: auto;
      }
    }
  }
  &__filename{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:after{
      content: '';
      position: absolute;
      top: 25px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $colorBlue70;
    }
  }
}
