@import "src/styles/constants";
@import "src/styles/breakpoint";

.message-to-reply-container{
  padding: 12px 32px 12px 24px;
  gap: 4px;
  @media only screen and (max-width: $screen-sm) {
    padding: 12px 16px 12px 16px;
  }
  &.withBG{
    background: $colorGray10;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid $colorGray30;
    .replied-message-container{
      display: -webkit-box;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @media only screen and (max-width: $screen-md) {
        width: calc(100vw - 386px);
      }
      @media only screen and (max-width: $screen-sm) {
        width: calc(100vw - 91px);
      }
    }
  }
  &-wrapper{
    border-left: 4px solid $colorBlue70;
    padding: 0 4px 0 27px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &__message{
      p{
        white-space: pre-line;
        margin-bottom: 0;
      }
    }
  }
  &-close{
    padding: 4px;
    &:hover{
      cursor: pointer;
    }
    svg{
      path{
        fill: $colorGray70;
      }
    }
  }
}