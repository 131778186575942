@import "src/styles/constants";
@import "src/styles/breakpoint";

.hs-details {
  position: relative;
.download-qr-code-icon{
  svg{
    path{
      fill: $colorWhite;
    }
  }
}
  &__main-content{
    padding: 16px 24px 0 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 32px;

    @media only screen and (max-width: $screen-sm) {
      padding: 16px 0 0 0;
    }
  }

  &_title{
    color: $colorGray70;
  }

  &_user-name{
    color: $colorGray90;
  }

  &__title-ha{
    margin: 0 0 6px 24px;
  }

  &__container{
    background: $colorGray10;
  }

  &__details-container{
    padding: 24px;
  }

  &__hospital-title{
    margin: 0 0 6px 24px;
  }
  &__title-general{
    margin-bottom: 16px;
  }
  &--empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    max-width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--no-padding {
    margin-top: 24px;
    padding: 0;

    .hs-details__title {
      justify-content: space-between;
    }

    .hs-details__tables-menu {
      .ant-menu-horizontal:not(.ant-menu-dark) {
        background-color: $colorWhite;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: flex-end;
  }
  &_title-text_container{
      &_label{
        margin-bottom: 12px;
    }
  }

  &__content {
    padding: 16px 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 32px;
  }

  &__edit-btn {
    align-self: flex-end;

    svg path {
      fill: $colorBlue70;
    }
  }

  &_icon-name_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__contact-info {
    svg path {
      fill: $colorGray70;
    }
  }

  &__users-table {
    margin-bottom: 32px;
    .cell-with-subtitle {
      &__title {
        .typography {
          max-width: 144px;
        }
      }
    }
  }

  &_name-container {
    display: flex;
    flex-direction: column;
  }

    &_contact-info{
      display: grid;
      grid-template-columns: repeat(2, calc(100% / 3));
      gap: 16px 24px;
      @media only screen and (max-width: $screen-sm) {
        display: flex;
        flex-direction: column;
      }
    }

    &_general-info {
      @media only screen and (max-width: $screen-md) {
        grid-template-columns: repeat(2, calc(100% / 2));
        .field-with-label {
          &:nth-of-type(4) {
            grid-column: 1 / 4;
            grid-row: 3;
          }
        }
      }
      @media only screen and (max-width: $screen-sm) {
       display: flex;
        flex-direction: column;
      }
      //height: 100%;
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));
      width: 100%;
      gap: 16px 24px;
      .field-with-label{
        &:nth-of-type(1) {
          grid-column: 1 / 4;
          grid-row: 1;
        }
    }
  }

  &_icon-name {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &_role-name{
    color: $colorGray60;
  }

  &_users-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .typography-label{
      margin-bottom: 4px;
    }
    .ant-btn {
      color: $colorGray90;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &_vs-president {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_avatar-group_container {
    display: flex;
    gap: 8px;
    margin-top: 4px;
  }

  &_users-list_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    gap: 0 24px;
    @media only screen and (max-width: $screen-lg) {
      grid-template-columns: repeat(2, calc(100% / 2));
    }
    @media only screen and (max-width: $screen-sm) {
      grid-template-columns: 100%;
    }
    .typography-label {
      margin-top: 8px;
    }
  }

  &_departments_container {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .hs-details_top-margin {
      margin-top: 12px;
    }
  }

  &_departments {
    max-width: 728px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &_departments_title-management {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .typography-label {
    color: $colorGray70;
    font-weight: 600;
  }

  .typography-caption {
    color: $colorGray60;
  }


  &_button-hide {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 32px;
  }

  &_button-hide_container {
    width: 100%;
    justify-content: flex-start;

    @media only screen and (max-width: $screen-md) {
      .ant-btn{
        height: 0;
        margin-bottom: 24px;
      }
    }

  }

  &_top-margin {
    margin-top: 8px;
  }

  .ant-avatar {
    border-color: $colorYellow50;
    font-size: 12px;
    line-height: 30px;
  }

  .ant-avatar-string {
    gap: 1px !important;
  }

  &_more-icon.ant-btn-link {
    display: flex !important;
    align-items: flex-start !important;
    margin-top: 7px;
  }

  &_count-gray {
    color: $colorGray50;
  }

  &_users-list_popup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    max-height: 704px;
    overflow: scroll;
  }

  &_hospital-button-hide{
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 16px;
  }

  &_users-list_popup-container {
    .ant-modal-footer {
      display: none;
    }

    .ant-modal-content {
      padding-bottom: 24px;
    }
  }

  .ant-btn.ant-btn-link.button-icon {
    height: 17px;
  }

  &__tables-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-menu-horizontal:not(.ant-menu-dark) {
      background-color: $colorGray10;

      .ant-menu-item {
        line-height: 20px;
        padding: 12px 8px;

        &:hover {
          .ant-menu-title-content {
            color: $colorGray90;
          }

          &::after {
            border-width: 4px;
          }
        }

        .ant-menu-title-content {
          font-size: 14px;
          line-height: 20px;
          color: $colorGray70;
        }

        &::after {
          width: 100%;
          border-width: 1px;
          border-color: $colorGray50;
          left: 0;
          right: 0;
        }
      }

      .ant-menu-item-selected {
        .ant-menu-title-content {
          color: $colorGray90;
        }

        &::after {
          border-width: 4px;
          border-radius: 2px 2px 0 0;
          border-color: $colorBlue70;
        }
      }
    }
  }

  &__tables-actions {
    display: flex;
    margin-bottom: 4px;
    .table-wrapper__header_buttons__edit-btn{
      margin-right: 8px;
    }
    &__edit-btn{
      margin-right: 8px;
    }

    .button-default {
      &:not(:first-of-type) {
        //margin-left: 8px;
      }
    }
  }
}
