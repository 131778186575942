@import "src/styles/constants";

.collapse-default {
  &.ant-collapse {
    border: none;

    .ant-collapse-header {
      padding: 12px 24px !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $colorGray90;
      background-color: $colorBlue10;
      box-shadow: 0 2px 4px rgba(24, 67, 98, 0.06);
      border-bottom: 1px solid $colorBlue30;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
      background-color: $colorGray10;
    }
  }
}
