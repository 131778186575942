@import "src/styles/constants";

.edit-group-chat {

  &_form {
    display: flex;
    flex-direction: column;
    margin-top: 12px !important;
    gap: 16px;
    width: 100%;
    overflow-y: auto;

    .ant-input-prefix {
      //scale: 0.7;
  }
  }

  &_separator {
    height: 1px;
    width: 100%;
    background-color: $colorGray30;
  }

  &_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
    padding-bottom: 16px;
  }

  

}
