@import "src/styles/constants";

.rfp-vendor-users-table {
  &.table-default {
    .ant-table-tbody {
      tr.ant-table-row.ant-table-row-level-0 td {
        background-color: $colorBlue10;

        &.ant-table-selection-column {
          display: none;
        }
      }
      .ant-table-cell.ant-table-selection-column{
        padding: 8px 0px 8px 46px;
      }
      &.ant-table-tbody td{
        padding: 8px 4px;
      }
    }
  }
}
