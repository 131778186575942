@import "src/styles/constants";

.cell-with-subtitle {
  &__title {
    .typography {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__subtitle {
    margin-top: 4px;
    color: $colorGray70;
  }
  &.disabled{
    .typography {
      color: $colorGray50;
    }
  }
}
