@import "src/styles/constants";
@import "src/styles/breakpoint";

.create-survey {
  display: flex;
  flex-direction: column;
  max-width: 752px;
  width: 100%;
  padding: 32px 24px;
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
}

.rfp-create-survey-popup{
  @media only screen and (max-width:$screen-sm-max) {
    &.ant-modal{
      margin: 0 !important;
      max-width: 100vw !important;
    }
    .ant-modal-body.ant-modal-body {
      overflow-y: scroll !important;
      min-height: calc(100vh - 200px);
      padding: 0 16px 16px;
    }
  }
  @media only screen and (max-width:$screen-sm-max) {
    height: 100vh;
  }
  .ant-modal-body {
    overflow-y: auto !important;
    padding: 0 32px 16px;
    margin-right: 0 !important;
  }
  .ant-modal-content{
    padding: 0;
  }

  .ant-modal-header{
    padding: 48px 32px 24px;
  }

  .ant-modal-footer{
   padding: 16px 32px 32px;
    box-shadow: 0px -2px 8px rgba(24, 67, 98, 0.08);
    border-radius: 0px 0px 8px 8px;
  }
}
.create-survey__header {
  display: flex;
  justify-content: space-between;
}
.create-survey__title {
  line-height: 34px;
}
.create-survey__actions {
  display: block;
  margin-top: 16px;
}
.rfp-create-survey {
  overflow-y: inherit !important;
  padding: 0
}
.create-survey__content {
  margin-top: 28px;
}
.create-survey__title--submitted {
  &::after {
    content: '(submitted)';
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    color: $colorGray70;
  }
}
