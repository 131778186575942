@import "src/styles/constants";

.container__status{
  .two-status{
    display: flex;
    gap: 12px;
    .two-status-separator {
      border-left: 1px solid $colorBlue40;
      padding-left: 12px;
    }
  }
}

.rfp-resend-bid-reason {
  &.files-info {
    width: 690px;
    position: absolute;
  }
}