@import "styles/constants";
.spend-by-product{
  &__table-header{
    border-top: 1px solid $colorBlue20;
    border-bottom: 1px solid $colorBlue20;
    height: 32px;
    min-height: 32px;
    background-color: $colorGray20;
    &__column{
      padding: 4px 12px;
      width: calc(100% / 3);
    }
  }
  .infinite-list-container{
    padding: 0;
  }
  .row-item-container {
    height: 32px;
    min-height: 32px;
    border-bottom: 1px solid $colorBlue20;
    &__item{
      padding: 8px 12px;
      max-width: calc(100% / 3);;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      width: calc(100% / 3);
    }
  }

}