@import "styles/constants";
.rfp-details-responsible {
  display: flex;
  flex-direction: column;
  &__commons-empty-state {
   gap: 6px;
    .typography{
      color: $colorGray60;
    }
  }
}
.rfp-details-responsible__actions {
  margin-top: 16px;
}
