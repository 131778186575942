@import "src/styles/breakpoint";

.add-chat-members__member {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  margin: 8px 6px !important;
  width: 100%;
  & .ant-checkbox {
    margin-bottom: 10px;
    margin-right: 11px;
  }
}
.add-chat-member__action {
  width: min-content;
}
.chat-members-infinite-list {
  display: flex;
  flex-direction: column;
}
.chat-add-members-group {
  width: 100%;
  height: 100%;
}
.chat-add-member__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}
.chat-add-members__spinner {
  padding: 12px 0;
  font-size: 24px;
}
.add-chat-members-popup{
  @media only screen and (max-width: $screen-sm) {
      .ant-modal-content{
        height: 100vh;
      }
      &.ant-modal{
        margin: 0 !important;
        max-width: 100vw !important;
      }
    .ant-modal-body.ant-modal-body {
      overflow-y: scroll !important;
      min-height: calc(100vh - 180px);
    }
    }
}

