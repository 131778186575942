@import "styles/constants";

.dots-items{
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  &:hover, &:active, &.ant-dropdown-open{
    cursor: pointer;
    background: $colorBlue20;
  }
}
.menu-items-wrapper{
  box-shadow: 0 4px 6px 2px rgba(77, 91, 102, 0.12) !important;
  &.ant-menu-vertical{
    border-right: none !important;
  }
  li{
    padding: 8px 12px !important;
    margin: 0 !important;
    &:hover{
      cursor: pointer;
      background-color: $colorBlue10;
    }
  }
}
