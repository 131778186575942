.price-file-content {
  display: flex;
  flex-direction: column;
  max-width: 364px;
  width: 100%;
  margin-bottom: 24px;
}
.price-file-content__attachments {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
