@import "styles/constants";

.financials-table {
  .ant-table-tbody {
    .ant-table-row {
      td.ant-table-cell {
        &:has(.red-cells) {
          background: $colorRed4 !important;
        }

        &:has(.yellow-cells) {
          background: $colorYellow10-2 !important;
        }
      }
    }
  }
}
