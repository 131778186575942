@import "src/styles/constants";

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  background-color: $colorBlue10;
  border: 1px dashed $colorBlue40;
  cursor: pointer;

  &--active,
  &:hover {
    background-color: $colorBlue20;
    border-color: $colorBlue80;
  }

  .typography {
    margin-top: 12px;
  }
}
