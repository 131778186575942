@import "src/styles/constants";
.verify-email-footer{
  .ant-statistic-content{
    color: $colorGray90;
    font-family: $font-family;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .button-default{
    padding: 0;
  }
}