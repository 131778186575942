@import "src/styles/constants";

.custom-select {
  &__dropdown {
    &.ant-select-dropdown {
      padding: 0;

      .ant-select-item {
        padding: 8px 12px;
        min-height: unset;
        color: $colorGray90;
        font-size: 16px;
        line-height: 24px;
      }

    .select-multi_option-text{
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: 90%;
      overflow: hidden;
    }

      .ant-select-item-group {
        padding: 8px 12px 4px;
        color: $colorGray70;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background: $colorBlue10;
      }
      .ant-select-item-option-disabled{
        background-color: $colorWhite;
        .ant-select-item-option-content{
          .select-multi_option-text{
            color: $colorGray50;
          }
        }
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: 400;
        background: $colorBlue10;
      }
      svg{
        display: flex;
      }
    }
  }

  &.ant-form-item {
    font-size: 16px;
    line-height: 24px;

    .ant-form-item-label {
      text-align: left;
      line-height: 16px;
      padding-bottom: 4px;

      label {
        height: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        &.ant-form-item-required {
          &::before {
            display: none;
          }

          &::after {
            content: '*';
            display: block;
            margin: 0;
            color: $colorRed40;
          }
        }
      }
    }

    .ant-form-item-explain-error {
      padding-top: 2px;
      color: $colorRed40;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    .ant-select-selection-placeholder {
      position: absolute;
      top: 50%;
      right: 11px;
      left: 11px;
      transform: translateY(-50%);
      color: $colorGray50;
    }

    .ant-select {
      .ant-select-selector {
        padding: 5px 11px;
        box-shadow: none !important;
        border-radius: 4px;
        border-color: $colorGray40;
        line-height: 24px;
        font-size: 16px;
        flex-wrap: nowrap;

        &:disabled {
          background-color: $colorGray10;
        }

        .ant-select-selection-overflow {
          max-width: 100%;
        }

        .ant-select-selection-search-input {
          height: auto;
        }

        .ant-select-selection-search {
          display: flex;
          align-items: center;
          margin: 0;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          margin: 0 12px 0 0;
          font-size: 16px;
          line-height: 24px;


          .ant-select-selection-item-remove {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            margin-left: -6px;
            margin-right: -6px;
          }

          .ant-select-selection-item-remove:hover {
            background: $colorBlue60;
            border-radius: 12px;
          }

          .ant-select-selection-item-remove:focus {
            background: $colorBlue40;
            border-radius: 12px;
          }

          .ant-select-selection-item-content {
            margin: 0;
          }
        }
      }
    }
  }
}

.ant-select-multiple .ant-select-selection-item {
  .ant-select-selection-item-content svg {
    display: none;
  }
}

.ant-select-item-option.select-multi_option.ant-select-item-option-selected {
  .select-multi_blank-checkbox {
    display: none;
  }

  display: grid;
  grid-template-columns:24px 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  align-items: center;
  height: 36px;

  &.ant-select-item-option-content {
    grid-column: 2;
    grid-row: 1;
    display: grid;
    grid-template-columns: 24px 100%;
    grid-template-rows: 1fr;
    width: 100%;
    padding: 0;
  }

  .ant-select-item-option-state {
    grid-column: 1;
    grid-row: 1;
  }
}


.ant-select-item-option-content {
  display: flex;
  gap: 12px;
  align-items: center;
}

.ant-select-item.ant-select-item-option.select-multi_option {
  &.ant-select-item-option-selected,
  .ant-select-item-option-active {
    padding: 0 12px;
    height: 40px;
    text-overflow: unset;
  }
}

.select-multi_blank-checkbox {
  grid-column: 1;
  grid-row: 1;
}
