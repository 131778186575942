@import "src/styles/constants";

.attachments-list {
 &__files{
   &.images{
     display: flex;
      gap: 4px;
   }
   &__container{
     position: relative;
     margin-bottom: 0px !important;
     border: 0.4px solid $colorYellow50;
     border-radius: 4px;
     img{
       object-fit: cover;
       height: 59.2px;
       width: 59.2px;
       border-radius: 3px;
     }
   }
   &__delete-btn{
     position: absolute;
     top: -8px;
     right: -8px;
     svg{
       &:hover{
          cursor: pointer;
       }
     }
   }
 }
}

.preview-image-modal{
  width: 676px !important;
  height: 700px;
  .ant-modal-content{
    border-radius: 8px;
    height: 700px;
    .ant-modal-close{
      padding: 24px 32px;
      .ant-modal-close-x{
        width: 24px;
        height: 24px;
        line-height: 24px;
      }
    }
    .ant-modal-body{
      display: flex;
      height: 100%;
      align-items: center;
      padding: 72px 32px 16px;
      img{
        border-radius: 4px;
        max-width: 612px;
        max-height: 611px;
        object-fit: contain;
      }
    }
  }
}